import { SkillType } from '../../../Types/SkillType';

export type SchemaType = {
	title: string;
	lines: Array<SkillType>;
};

export const schema: SchemaType = {
	title: 'Skills',
	lines: [
		{
			entry: 'Developer Experience (DX)',
		},
		{
			entry: 'User Experience (UX)',
		},
		{
			entry: 'Code Quality',
		},
		{
			entry: 'Leadership',
		},
		{
			entry: 'Clarity of Thought',
		},
		{
			entry: 'Critical Thinking',
		},
		{
			entry: 'Data Structuring',
		},
		{
			entry: 'Problem Structuring',
		},
		{
			entry: 'Information Architecture',
		},
		{
			entry: 'Software Architecture',
		},
	],
};
