import { useCase as spider } from './/spider';
import { useCase as COCs } from './/COCs';
import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'bambaCOCs',
		section: 'Bamba',
		content: COCs,
		tags: ['agility'],
	},
	{
		id: 'spider',
		section: 'Bamba',
		content: spider,
		tags: ['agility'],
	},
];
