import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../../../Constants';

export const ExperienceWrapper = styled('div')``;

export const ProfessionalExperienceSectionWrapper = styled('div')`
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid ${cssVarColors.surface};
	@media print {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0.25rem;
		padding-bottom: 1rem;
	}
`;

export const ProfessionalSectionEntriesWrapper = styled('div')``;
