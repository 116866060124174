import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~4 years',
	timespan: 'Nov 2022 - Present,;June 2020 - May 2022',
	title: 'Staff Software Engineer / Independent Contractor',
	technologies: ['Flask', 'Django', 'ReactJS', 'React Native', 'NodeJS', 'SQL (OLTP)'],
	tools: ['GCP', 'MS Azure', 'Ansible', 'Docker', 'CircleCI', 'Cypress'],
	languages: ['JavaScript', 'Python', 'Typescript', 'Bash Scripting', 'Kotlin', 'Swift'],
	company: 'The Helper Bees',
	description: 'A company that provides seniors with tools to help them age-in-place',
	location: 'Austin, Texas (Remote)',
	results: 'Jan 2022 - 12.8M Series B raise',
	details: [
		{
			showMeOn: ['all'],
			autonomous: true,
			// entry: 'Improved Ops Team throughput by 50% by spearheading a UX redesign of Ops tool suite',
			entry: 'Improved Ops Team throughput by 50% by spearheading a complete UX redesign of the Operations tool suite',
		},
		{
			autonomous: true,
			showMeOn: ['all'],

			entry: 'Led Developer Experience overhaul of monolithic architecture, increasing software team efficiency by 75%',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Reduced partner onboarding time from 2 weeks to 30 minutes by automating the onboarding process',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Cut firefighting efforts by 90% with introduction of a Slack Bot for automatic retrieval of historical communication context',
		},
		{
			showMeOn: ['all'],
			// entry: "Mentored developers, improving team performance and code quality",
			// entry: "Mentored developers, fostering a culture of learning and code quality,",
			entry: 'Mentored developers, fostering a culture of learning and improving overall team efficiency and collaboration',
		},
		{
			showMeOn: ['all'],
			entry: 'Empowered teams and improved decision making  with data-driven insights by developing a real-time KPI dashboard',
		},
		{
			showMeOn: ['all'],
			entry: 'Conducted sprint planning and retrospectives sessions, improving team throughput and ensuring accurate sprint scoping',
		},
	],
};
