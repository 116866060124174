import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~2 years',
	timespan: '2012 - 2014',
	title: 'Software Engineer',
	technologies: [],
	tools: [],
	languages: [],
	// languages: ['APEX'],
	company: 'BigKite Consulting',
	description: 'A SalesForce consulting company',
	location: 'Toronto, Ontario',
	results: '',
	// results: 'Sept 2015 - Acquired by Traction (https://tractionondemand.com)',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Led design and implementation of custom Salesforce solutions that automated workflows improving efficiency for clients',
		},
		{
			showMeOn: ['frontend', 'fullstack'],
			entry: 'Developed custom user interfaces using Visualforce, enhancing client user experience',
		},
		{
			showMeOn: ['backend', 'fullstack'],
			entry: 'Designed custom Salesforce objects and relational table structures to efficiently store and organize complex client data, enabling streamlined reporting and automation of business processes',
		},
	],
};
