import styled from 'styled-components';
import { cssVarColors } from '../../Constants';
import { mobileThreshold } from '../../Constants/layoutValues';

export const SectionTitleWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	font-size: 1.5em;
	font-family: spacemono;
	margin-bottom: 0em;
	width: 100%;
	@media print {
		font-size: 1.25em;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-bottom: 0.25rem;
	} ;
`;

export const SectionTitleCentered = styled('div')`
	border-bottom: 3px solid ${cssVarColors.highlight};
	@media print {
		border-bottom: 1.5px solid ${cssVarColors.highlight};
	}
`;

export const SectionTitleFullWidth = styled('div')`
	border-bottom: 3px solid ${cssVarColors.highlight};
	width: 100%;
	flex: 1;
	display: flex;
	@media print {
		border-bottom: 1.5px solid ${cssVarColors.highlight};
	}
`;
