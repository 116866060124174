import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Core Competence',
	lines: [
		{
			entry: 'Autonomy',
			showOn: ['all'],
		},
		{
			entry: 'Entrepreneurship',
			showOn: ['all'],
		},
		{
			entry: 'Adaptability',
			showOn: ['all'],
		},
		{
			entry: 'Collaboration',
			showOn: ['all'],
		},
		{
			entry: 'Learning / Curiosity',
			showOn: ['all'],
		},
		{
			entry: 'Teaching / Mentoring',
			showOn: ['all'],
		},
		{
			entry: 'Ownership',
			showOn: ['all'],
		},
		{
			entry: 'Articulation',
			showOn: ['all'],
		},
		{
			entry: 'Clarity of Thought',
			showOn: ['all'],
		},
		{
			entry: 'Critical Thinking',
			showOn: ['all'],
		},
		{
			entry: 'Problem Structuring',
			showOn: ['all'],
		},
		{
			entry: 'Empathy',
			showOn: ['all'],
		},
		{
			entry: 'Unit Economics',
			showOn: ['all'],
		},
	],
};
