export const schema = [
	// Company
	{
		title: 'Milestones',
		links: [
			{
				title: 'External Milestone Funnel',
				link: '/milestones/externalFunnel',
			},
			{
				title: 'Internal Milestone Funnel (development)',
				link: '/milestones/internalFunnel',
			},
		],
	},
	{
		title: 'Visitors',
		links: [
			{
				title: 'Distance',
				link: '/visitors/distance',
			},
			{
				title: 'Velocity',
				link: '/visitors/velocity',
			},
			{
				title: 'Sessions',
				link: '/visitors/sessions',
			},
		],
	},
	{
		title: 'Categories',
		links: [
			{
				title: 'Distance',
				link: '/categories/distance',
			},
		],
	},
	{
		title: 'Users',
		links: [
			{
				title: 'Distance (In Development)',
				link: '/users/distance',
			},
			{
				title: 'Velocity (In Development)',
				link: '/users/velocity',
			},
			{
				title: 'Listing',
				link: '/users/listing',
			},
		],
	},
	{
		title: 'Activity Centers',
		links: [
			{
				title: 'Overview (In Development)',
				link: '/activityCenters/overview',
			},
		],
	},
	// matching
	{
		title: 'Bookings',
		links: [
			{
				title: 'Distance (In Development)',
				link: '/bookings/distance',
			},
			{
				title: 'Velocity (In Development)',
				link: '/bookings/velocity',
			},
			{
				title: 'Listing',
				link: '/bookings/listing',
			},
		],
	},
	{
		title: 'Traction',
		links: [
			{
				title: 'All Traction',
				link: '/traction/allTraction',
			},
		],
	},
	{
		title: 'Dev Links',
		links: [
			{
				title: 'Waterfall',
				link: '/notifications/waterfall',
			},
		],
	},
];
