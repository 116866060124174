import * as React from 'react';
import { ProfessionalExperienceEntriesType } from '../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntriesType = [
	{
		technologies: [],
		tools: [],
		timespan: 'April 2021 – Present',
		length: '3 years',
		title: 'Owner & Principal Consultant',
		company: 'RAZI STRATEGIES (Government Relations)',
		description: "Develop and execute high-level public affairs strategies, advising clients on navigating Canada's political landscape, securing government funding, and leading key lobbying initiatives.",
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: "Develop and execute high-level public affairs strategies, advising clients on navigating Canada's political landscape, spearheading efforts to secure government funding, and leading key lobbying initiatives that deliver tangible outcomes for clients.",
				showMeOn: ['all'],
			},
			{
				entry: 'Build relationships with senior government officials, policymakers, and industry leaders to influence decision-making and align client goals with policy frameworks.',
				showMeOn: ['all'],
			},
			{
				entry: 'Manage client engagements, ensuring the successful execution of campaigns and executing client deliverables through strategic advisory and leadership.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'January – August 2024',
		length: '7 months',
		title: 'Principal – General and International Trade',
		company: 'HOMEWARD PUBLIC AFFAIRS/ ONE CANADA STRATEGY GROUP (Government Relations)',
		description: 'Led strategic direction and brand promotion for government relations.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Led strategic direction and brand promotion, including driving business development, creating strategic partnerships and expanding the firm’s client base locally and internationally.',
				showMeOn: ['all'],
			},
			{
				entry: 'Drafted client proposals and designed targeted communications and government relations campaigns, including securing government funding and advocating for policy and legislative changes.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'October 2022 – October 2023',
		length: '1 year',
		title: 'Director of Public Affairs – Policy and Public Affairs',
		company: 'LUNG HEALTH FOUNDATION (LHF)',
		description: 'Led public affairs team and developed national policy agendas for lung health.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Led the Public Affairs team, formulating and executing comprehensive government relations strategies, resulting in the development of a National Lung Health Agenda.',
				showMeOn: ['all'],
			},
			{
				entry: 'Oversaw the establishment of the National Lung Health Alliance, uniting provincial associations to advance national policy objectives.',
				showMeOn: ['all'],
			},
			{
				entry: 'Directed high-stakes advocacy campaigns, including federal advocacy days, and cultivated strategic relationships with legislators, senior government officials, and stakeholders.',
				showMeOn: ['all'],
			},
			{
				entry: 'Designed and implemented LHF’s Indigenous outreach strategy, ensuring inclusivity and expanding the organization’s reach into underrepresented communities.',
				showMeOn: ['all'],
			},
			{
				entry: 'Led the execution of the Global Lung Cancer Policy Forum, engaging global thought leaders and advancing discussions on best practices in healthcare policy.',
				showMeOn: ['all'],
			},
			{
				entry: 'Hired, managed, and collaborated with external vendors and government relations consultants to fill project resource gaps.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'September 2021 – April 2022',
		length: '8 months',
		title: 'Government Relations Consultant – General Practice',
		company: 'PROOF STRATEGIES (Government Relations)',
		description: 'Led client advocacy campaigns, provided counsel, and managed crisis strategies during COVID-19.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Led client-focused advocacy and public relations campaigns, providing expert counsel to Fortune 500 companies, start-ups and trade associations.',
				showMeOn: ['all'],
			},
			{
				entry: 'Directed crisis management strategies during the COVID-19 pandemic, ensuring clients’ policy objectives were met and critical government relations strategies were effectively implemented.',
				showMeOn: ['all'],
			},
			{
				entry: 'Organized and moderated high-profile client events, such as industry panels and lobby days, strengthening relationships with key stakeholders.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'February – July 2020',
		length: '6 months',
		title: 'Health Strategist – Healthcare Practice',
		company: 'ENTERPRISE CANADA (Government Relations)',
		description: 'Provided strategic counsel for healthcare clients, including responses to the COVID-19 pandemic.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Provided strategic political, policy, and government relations counsel to healthcare clients during COVID-19.',
				showMeOn: ['all'],
			},
			{
				entry: 'Secured critical government funding and exemptions for healthcare clients.',
				showMeOn: ['all'],
			},
			{
				entry: 'Led a dynamic team in developing and executing high-impact COVID-19 response strategies for healthcare clients, navigating complex policy environments',
				showMeOn: ['all'],
			},
			{
				entry: 'Coached and mentored junior consultants, ensuring deliverables were met.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'February 2018 – February 2020',
		length: '2 years',
		title: 'Public Policy Analyst – Policy and Government Relations',
		company: 'SPINAL CORD INJURY ONTARIO (SCIO)',
		description: 'Led policy advocacy for healthcare reforms and directed national-level campaigns.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: "Directed SCIO’s 2019 Federal Elections Strategy, elevating the organization's visibility at the national level.",
				showMeOn: ['all'],
			},
			{
				entry: 'Led advocacy efforts for healthcare policy reforms, including securing a $90M initiative for medical supply funding.',
				showMeOn: ['all'],
			},
			{
				entry: 'Co-designed SCIO’s Peer Activist Recruitment Strategy for grassroots advocacy.',
				showMeOn: ['all'],
			},
			{
				entry: 'Engaged key government officials through outreach efforts to build relationships with policymakers.',
				showMeOn: ['all'],
			},
			{
				entry: 'Led integrated lobby days and prepared speeches for SCIO campaigns.',
				showMeOn: ['all'],
			},
			{
				entry: 'Hosted consultations with healthcare and disability partners and represented SCIO on advisory committees.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'January 2013 – May 2016',
		length: '3 years 5 months',
		title: 'Research Analyst - Public Policy and Public Affairs',
		company: 'ONTARIO HOSPITAL ASSOCIATION (OHA)',
		description: 'Co-led initiatives to secure hospital revenue savings and provided counsel for healthcare capacity planning.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Co-led initiatives to secure $20M in hospital revenue savings through GST/HST exemptions.',
				showMeOn: ['all'],
			},
			{
				entry: 'Worked directly with the Chief System Planning Officer to drive a $25M healthcare capacity planning initiative.',
				showMeOn: ['all'],
			},
			{
				entry: 'Represented OHA in high-profile advocacy working committees and panels.',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'Prior to 2013',
		length: '',
		title: 'Pan/Parapan American Games Secretariat, Economic Analyst – Infrastructure and Special Projects',
		company: 'GOVERNMENT OF ONTARIO',
		description: "Develop and execute high-level public affairs strategies, advising clients on navigating Canada's political landscape, securing government funding, and leading key lobbying initiatives.",
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Created a comparative multi-jurisdictional briefing book containing environmental scans of international games hosting countries and recommended best practices on cross-government planning for the implementation of Toronto 2015 Pan / Parapan American Games - a $2.5 Billion legacy project for the City of Toronto',
				showMeOn: ['all'],
			},
		],
	},
	{
		technologies: [],
		tools: [],
		timespan: 'Prior to 2013',
		length: '7 months',
		title: 'Ministry of Citizenship and Immigration, Research Analyst – Ontario Provincial Nominee Program',
		company: 'GOVERNMENT OF ONTARIO',
		description: 'Led strategic direction and brand promotion for government relations.',
		location: 'Toronto, ON',
		showMeOn: ['all'],
		details: [
			{
				entry: 'Researched and developed the policy framework for the Opportunities Ontario Provincial Nominee Program (OOPNP) and recommended revisions to its Permanent Employment Criteria for foreign workers in Canada.',
				showMeOn: ['all'],
			},
		],
	},
];
