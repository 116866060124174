import * as React from 'react';
import { BulletsWrapper, BulletWrapper, IntroWrapper, ProfileWrapper, SpecialCompetenciesBulletsWrapper, SpecialCompetenciesHeadingWrapper, SpecialCompetenciesWrapper } from './ProfileStyles';
import { language } from './ProfileLanguage';
import { SchemaType as ProfileSchemaType } from '../../../../Schema/MariamCVs/default/AboutMe';

export type Props = {
	profileSchema: ProfileSchemaType;
};

export const ProfileLayout = (props: Props) => {
	const render = () => {
		const bulletsJSX = generateBullets(props.profileSchema.bullets);
		const returnValue = (
			<ProfileWrapper>
				<IntroWrapper>{props.profileSchema.intro}</IntroWrapper>
				<SpecialCompetenciesWrapper>
					<SpecialCompetenciesHeadingWrapper>{language.specialCompetenciesHeading}</SpecialCompetenciesHeadingWrapper>
					<SpecialCompetenciesBulletsWrapper>{bulletsJSX}</SpecialCompetenciesBulletsWrapper>
				</SpecialCompetenciesWrapper>
			</ProfileWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export const generateBullets = (bullets: Array<string>) => {
	const internal = bullets.map((bullet) => {
		const innerReturnValue = <BulletWrapper>{bullet}</BulletWrapper>;
		return innerReturnValue;
	});
	const returnValue = <BulletsWrapper>{internal}</BulletsWrapper>;
	return returnValue;
};
