import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { KidiUp } from '../../Employment/KidiUp';

export const useCase: UseCaseType = {
	title: 'Aurora Street Festival',
	image: 'https://assets.dear-ux.com/faizhirani/images/Aurora Street Festival.jpg',
	employment: KidiUp,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["To support the Operations team, every week a member of the Software Team was designated 'On Triage'. This Triage Developer would deal 'Triages', which consisted of custom requests from Operations, issues, and bugs. When designated, Triage Developers were known to have no left-over capacity to contribute to the Software Team's goals.", 'Over 75% of Triages were recurring. However, the designated Triage Developer was often unfamiliar with previous instances and would seek assistance from the Software Team. There would then be a discussion in the #Triage Slack channel in which other developers, who had previously encountered a similar Triage would bring an old Slack thread to the forefront, or more likely, would just reissue a dissertation on how to resolve the Triage.', 'Developers were very aware of when their turn on Triage was coming up, and as it approached, there was a noticeable decline in their enthusiasm. Also, due to its demanding nature, new hires were not integrated into the Triage rotation for at least 6 months.', 'To me, it seemed extremely inefficient to dump this much of our software resources into recurring issues and requests. I decided to find a way to streamline the process.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ["I created a Triage Bot using Slack's API and integration tools.", 'Every time an issue or request was raised, the Triage Bot:', ['searched the #Triage channel for previous threads about the same issue;', 'compiled all discussions in those threads; and', 'and responded in thread with this context.'], 'During development, I found that it was useful to use keywords to pin in-thread messages that would be prioritized by Triage Bot in subsequent lookups. This enabled developers to leave notes that Triage Bot would put at the top of its response the next time around.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['Triage Bot had massive qualitative and quantitative effects to the demanding nature of Triage.', 'Quantitatively, it streamlined the over 75% of triages that were recurrences. Those issues were efficiently addressed by the Triage Developer without the need for intervention.', 'Consequently:', ["with the less demanding triage load, the Triage Developer's contribution capacity to the Software Team's goals rose to ~50%;", 'the reduced demand for attention in the #Traige channel improved the productivity of the entire Software Team; and ', 'the simplified process eliminated the need to hold new hires off the Triage rotation for 6 months.'], 'Qualitatively, the angst of your turn on Triage coming up dissolved. People no longer felt a resigned dread when they were called upon do Triage.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['This is an anomalous use case as it is not directly about software. However, our success at the Aurora Street Festival was stood on the shoulders of the  agility of our software.', "The <href='/portfolio/useCase?id=modelServiceStack'>KidiUp network architecture</href> was built on the Model Service Stack, optimized for agility. This allowed for fast, reliable software iterations with negligible technical debt. I was able to spend three days — an eternity in the startup world — working outside of the software space. During this time, I was confident there would be no fires to fight or network faults to manage, ensuring no risk to our other deadlines.", "Additionally, my understanding of the required collateral for such an event, and how it should look, came from my experience at Chowbotics. By building <href='/portfolio/useCase?id=modelServiceStack'>Chowbotics’ network infrastructure</href> to prioritize agility, I had ample time to work with our Marketing Team and assist in driving consumers to our Sallys. During this time, I gained valuable insights into how marketing events, such as festival booths, should be approached. The agility of Chowbotics' infrastructure afforded me the space to gain this knowledge by supporting the marketing team and looking beyond just software."],
		},
	],
};
