import { ProfessionalExperienceEntriesType } from '../../../Types/ProfessionalExperienceLineType';
import { schema as Ministry } from './Professional Experience/Ministry';
import { schema as CLS } from './Professional Experience/CLS';
import { schema as DVS } from './Professional Experience/DVS';
import { schema as Chowbotics } from './Professional Experience/Chowbotics';
import { schema as KidiUp } from './Professional Experience/KidiUp';
import { schema as HelperBees } from './Professional Experience/HelperBees';
import { schema as BigKite } from './Professional Experience/BigKite';
import { schema as Bamba } from './Professional Experience/Bamba';

export const schema: ProfessionalExperienceEntriesType = {
	title: 'Professional Experience',
	entries: [HelperBees, KidiUp, Chowbotics, Bamba, BigKite, CLS, DVS, Ministry],
};
