import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';
import { useCase as timeMatters } from './timeMatters';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'timeMatters',
		section: 'Community Legal Services',
		content: timeMatters,
		tags: ['agility'],
	},
];
