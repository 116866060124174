import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../Constants';
import { mobileThreshold } from '../../Constants/layoutValues';

export const PaneWrapper = styled('div')`
	background-color: ${cssVarColors.surface};
	padding-top: 1.5rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	padding-right: 2rem;
	width: calc(100% - 4rem);
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 1rem;
		padding-right: 1rem;
		width: calc(100% - 2rem);
	} ;
`;

export const PaneTitle = styled('div')`
	font-family: montserratSemiBold;
	font-size: 1.2rem;
	padding-bottom: 1rem;
`;

export const PaneBody = styled('div')``;
