import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';
import { useCase as modelServiceStack } from './modelServiceStack';
import { useCase as integratedSchedules } from './ActivityCenterIntegration';
import { useCase as auroraStreetFestival } from './AuroraStreetFestival';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'modelServiceStack',
		section: 'KidiUp',
		content: modelServiceStack,
	},
	{
		id: 'scheduleIntegration',
		section: 'KidiUp',
		content: integratedSchedules,
		tags: ['agility'],
	},
	{
		id: 'auroraStreetFestivals',
		section: 'KidiUp',
		content: auroraStreetFestival,
	},
];
