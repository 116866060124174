import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: `${new Date().getFullYear() - 2000} years`,
	technologies: [],
	tools: [],
	languages: [],
	timespan: '2000 - Present',
	title: 'Unspeakable',
	company: 'Department of Mysteries (Ministry of Magic)',
	description: '???',
	location: 'London, England',
	details: [],
};
