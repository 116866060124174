import * as React from 'react';
import { LanguagesTitleWrapper, LanguagesWrapper } from '../ProfessionalExperienceSectionStyles';
import { language } from '../ProfessionalExperienceSectionLanguage';

export type Props = {
	languages: Array<string>;
};

export const LanguagesLayout = (props: Props) => {
	const languages = generateLanguages(props.languages.sort());
	const render = () => {
		const returnValue =
			props.languages.length === 0 ? null : (
				<LanguagesWrapper>
					<LanguagesTitleWrapper>{language.languagesHeader}:</LanguagesTitleWrapper>
					{languages}
				</LanguagesWrapper>
			);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateLanguages = (languages: Array<string>) => {
	const returnValue = languages.join(', ');
	return returnValue;
};
