import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	timespan: '2025',
	length: '3 months', // adjust if needed
	noLeftColumn: true,
	title: 'Udemy - Deep Learning A-Z',
	company: '2025',
	description: 'Comprehensive course covering deep learning fundamentals, real-world applications, and hands-on coding with state-of-the-art tools.',
	location: 'Online',
	technologies: ['Deep Learning'],
	languages: ['Python'],
	tools: ['TensorFlow', 'PyTorch', 'Theano', 'Keras', 'Scikit-learn', 'NumPy', 'Pandas', 'Matplotlib'],
	skillsAcquired: ['Neural Network Architectures (ANN, CNN, RNN)'],
	details: [
		{
			showMeOn: ['all'],
			entry: 'Developed an intuitive understanding of deep learning principles and architectures, including both supervised and unsupervised techniques',
		},
		{
			showMeOn: ['all'],
			entry: 'Implemented projects such as customer churn prediction, image recognition, stock price forecasting, fraud detection, and recommender systems',
		},
		{
			showMeOn: ['all'],
			entry: 'Gained hands-on experience coding models from scratch and leveraging frameworks to build, train, and evaluate deep learning solutions',
		},
		{
			showMeOn: ['all'],
			entry: 'Learned to compare and apply different frameworks (TensorFlow, PyTorch, Theano, and Keras) to select the optimal approach for given challenges',
		},
	],
};
