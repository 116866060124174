import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~1 year',
	timespan: '2005 - 2006',
	title: 'Junior Software Engineer / Software Engineer',
	languages: ['C++', 'C#', 'VB.NET'],
	technologies: ['.Net', 'SQL (SQLite)'],
	tools: [],
	company: 'Dominion Voting Systems',
	description: 'A company that makes voting machines to be used in elections',
	location: 'Toronto, Ontario',
	results: '75M annual revenue',
	employeeNumber: {
		type: 'approximate',
		number: 14,
	},
	details: [
		{
			autonomous: true,
			showMeOn: ['all'],
			// entry: 'Averted a public election-day crisis by reducing 90-second ballot processing time to 1 second under election day conditions that was discovered in late stage stress-testing, by inventing a proprietary file system that bypassed the limitations available filesystems',
			entry: 'Averted public election day crisis by reducing ballot processing time from 90 to 1 second under election day conditions, discovered during late-stage stress testing, by inventing proprietary file system that bypassed existing filesystem limitations',
		},
		{
			showMeOn: ['none'],
			entry: 'Enabled deployment across diverse election environments by engineering configuration software for polling machine',
		},
		{
			showMeOn: ['frontend'],
			entry: 'Empowered visually impaired voters to cast ballots by designing and implementing a buzzer-based audio voting UX',
		},
	],
};
