import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Tools',
	lines: [
		{
			entry: 'Handlebars',
			showOn: ['marketing'],
		},
		{
			entry: 'AWS CDK',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Resource Manager (ARM) templates',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Deployment Manager',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS SDK',
			showOn: ['aws'],
		},
		{
			entry: 'Azure SDK',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud SDK',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS CLI',
			showOn: ['aws'],
		},
		{
			entry: 'Azure CLI',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud CLI',
			showOn: ['gcp'],
		},
		{
			entry: 'Terraform',
			showOn: ['devops'],
		},
		{
			entry: 'Ansible',
			showOn: ['devops'],
		},
		{
			entry: 'AWS Systems Manager',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Automation',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Deployment Manager',
			showOn: ['gcp'],
		},
		{
			entry: 'Docker',
			showOn: ['devops'],
		},
		{
			entry: 'AWS ECS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Container Instances',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Run',
			showOn: ['gcp'],
		},
		{
			entry: 'Kubernetes',
			showOn: ['devops'],
		},
		{
			entry: 'AWS EKS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Kubernetes Service (AKS)',
			showOn: ['azure'],
		},
		{
			entry: 'Google Kubernetes Engine (GKE)',
			showOn: ['gcp'],
		},
		{
			entry: 'Postman',
			showOn: ['backend', 'qa', 'fullstack'],
		},
		{
			entry: 'Swagger',
			showOn: ['backend', 'qa', 'fullstack'],
		},
		{
			entry: 'Jest',
			showOn: ['qa', 'fullstack'],
		},
		{
			entry: 'Cypress',
			showOn: ['qa', 'fullstack'],
		},
		{
			entry: 'Jira',
			showOn: ['scrum'],
		},
		{
			entry: 'Trello',
			showOn: ['scrum'],
		},
		{
			entry: 'Slack',
			showOn: ['communication'],
		},
		{
			entry: 'Notion',
			showOn: ['communication'],
		},
		{
			entry: 'Excalidraw',
			showOn: ['scrum'],
		},
		{
			entry: 'InVision',
			showOn: ['scrum'],
		},
		{
			entry: 'Git',
			showOn: ['all'],
		},
		{
			entry: 'CircleCI',
			showOn: ['all'],
		},
		{
			entry: 'Twilio',
			showOn: ['integration'],
		},
		{
			entry: 'Tableau',
			showOn: ['data'],
		},
		{
			entry: 'Power BI',
			showOn: ['data'],
		},
		{
			entry: 'Hootsuite',
			showOn: ['marketing'],
		},
		{
			entry: 'Sentry',
			showOn: ['bugs'],
		},
		{
			entry: 'Data Dog',
			showOn: ['bugs'],
		},
	],
};
