import * as React from 'react';
import { EducationItemLayout, Props as EducationItemProps } from '../Layouts/EducationItemLayout';
import { EducationEntryType } from '../../../../../Types/EducationType';

export type Props = {
	lines: Array<EducationEntryType>;
};

export const generateEducationItem = (props: Props) => {
	const returnValue = props.lines.map((line: EducationEntryType) => {
		const educationItemProps: EducationItemProps = {
			entry: line,
		};
		const innerReturnValue = <EducationItemLayout {...educationItemProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
