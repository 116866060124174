import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~2 years',
	timespan: '2018 - 2020',
	title: 'Senior Software Engineer / Staff Software Engineer',
	languages: ['Typescript', 'Javascript', 'Assembly', 'Kotlin', 'C++'],
	technologies: ['Angular', 'ReactJS', 'React Native', 'NodeJS (Express)', 'SQL (OLTP / OLAP)', 'NoSQL' /*'Handlebars'*/],
	tools: ['AWS', 'GCP'],
	company: 'Chowbotics',
	description: 'A company that manufactured and sold food robots',
	location: 'Hayward, California',
	employeeNumber: {
		type: 'approximate',
		number: 25,
	},
	results: 'Oct 2020 - Acquired by {DoorDash, https://about.doordash.com/en-ca/company}',

	details: [
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Scaled support capacity from 10 to 150+ robots of a 3-person Customer Success team by introducing a notification service that optimized field-to-operations information flow, subverting the need to increase staffing',
		},
		{
			showMeOn: ['all'],
			entry: 'Facilitated growth by removing deployment limitation of ~15 robots by transitioning from monolith to distributed architecture',
		},
		{
			showMeOn: ['all'],
			entry: 'Increased robot uptime from 40% to 95% by implementing recovery routines that resolved or mitigated mechanical failures',
		},
		{
			showMeOn: ['all'],
			entry: 'Increased deployment success rate by 25% by developing a real-time monitoring portal that boosted partner engagement',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Improved meal satisfaction by 50% through collaboration with Culinary Team in using localized data to optimize offerings',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Boosted campaign participation by 30% by providing actionable data that helped Marketing optimize campaign strategies',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Reduced time-to-meal by 20% by implementing crowd-sourced suggestion engine to assist in building delicious meals',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Resolved 50+ hidden UX friction points identified through subtle body language cues observed at on-site sessions',
		},
		{
			showMeOn: ['all'],
			entry: 'Reduced my personal frustration over internal misunderstandings by ∞% by establishing & enforcing common terminology',
		},
	],
};
