import styled from 'styled-components';
import { cssVarColors } from '../../../../../Constants';
import { mobileThreshold } from '../../../../../Constants/layoutValues';

export const ProfessionalExperienceSectionWrapper = styled.div`
	padding-top: 1.5em;
	padding-left: 1rem;
	@media print {
		padding-left: 0.25rem;
	}
`;

export const ProfessionalSectionEntriesWrapper = styled('div')``;

export const EntryWrapperEven = styled('div')`
	margin-left: -0.75rem;
	padding-left: 0.75rem;
	display: flex;
	flex-direction: row;
	page-break-inside: auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	:first-of-type {
		padding-top: 0;
	}

	:last-of-type {
		padding-bottom: 0;
	}

	@media (max-width: ${mobileThreshold}px) {
		flex-direction: column;
	}
`;

export const EntryWrapperOdd = styled(EntryWrapperEven)`
	background-color: ${cssVarColors.highlightAlpha};
`;

export const EntryColumnHeadingWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	@media print {
		padding-left: 10px;
	}
`;

export const TitleLine = styled('div')`
	display: flex;
	flex-direction: row;
	width: 100%;
	position: relative;
	padding-bottom: 0.2rem;
	@media (max-width: ${mobileThreshold}px) {
		flex-direction: column;
	}
`;

export const Title = styled('div')`
	font-family: montserratSemiBold;
	color: ${cssVarColors.primary};
	padding-bottom: 0.25rem;
`;

export const Spacer = styled('div')`
	font-style: italic;

	&::after {
		padding-right: 0.75rem;
		content: ', ';
		@media print {
			padding-right: 0.5em;
		}
		@media (max-width: ${mobileThreshold}px) {
			display: none;
			content: '';
		}
	}
`;

export const Company = styled('div')`
	font-style: normal;
	font-family: montserratSemiBold;
	text-transform: uppercase;
	@media (max-width: ${mobileThreshold}px) {
	}
`;

export const Location = styled('div')`
	font-style: normal;
	@media (max-width: ${mobileThreshold}px) {
		font-size: 0.9rem;
	}
`;

export const DateColumnWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: flex-end;
	padding-right: 0.5rem;
	@media print {
	}
	@media (max-width: ${mobileThreshold}px) {
		align-items: flex-start;
		font-size: 0.9rem;
	}
`;

export const DateWrapper = styled('div')`
	font-size: 0.9em;
	font-family: montserratSemiBold;
	height: fit-content;
	display: flex;
	flex-direction: column;
	@media print {
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-right: 2rem;
		width: fit-content;
	}
`;

export const DetailsWrapper = styled('div')`
	font-size: 0.9em;
	line-height: 1.6em;
	page-break-inside: auto;
	@media print {
		font-size: 1em;
	}
`;

export const UnorderedList = styled('ul')`
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	margin-top: 0;
	@media print {
		padding-right: 0;
		page-break-inside: auto;
		padding-left: 1.5em;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 1.25rem;
	}
`;

export const TimespanWrapper = styled('div')``;

export const EntryContent = styled('li')``;

export const LengthWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const MajorAccomplishmentWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const EmployeeNumberWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const ResultWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const EmploymentDetails = styled('div')`
	padding-top: 0rem;
	line-height: 1em;
`;

export const DescriptionWrapper = styled('div')`
	display: flex;
	//padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const ToolsWrapper = styled('div')`
	display: flex;
	//padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.85em;
	padding-left: 0rem;
	//font-style: italic;
	//flex-direction: column;
`;

export const TechnologiesWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.85em;
	padding-left: 0rem;
	//font-style: italic;
	flex-direction: row;
`;

export const ToolsTitleWrapper = styled('div')`
	font-family: montserratMedium;
	padding-right: 0.25rem;
`;

export const ToolWrapper = styled('div')`
	display: flex;
	//padding-left: 0.1rem;
	padding-right: 0.25rem;
`;

export const TechnologyTitleWrapper = styled('div')`
	padding-right: 0.25rem;
	font-family: montserratMedium;
	text-decoration: none;
	@media print {
	}
`;

export const TechnologyWrapper = styled('div')`
	display: flex;
	padding-right: 0.25rem;
`;
