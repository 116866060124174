export type SchemaType = {
	title: string;
	quote: string;
	intro: string;
	bullets: Array<string>;
};

export const schema = {
	title: 'About Me',
	quote: '"I will never climb a mountain... there has to be a better way."',
	intro: 'A seasoned Public Policy and Government Affairs professional with nearly a decade of progressive experience in political consulting, policy research, and public affairs. Proven track record of providing strategic counsel to clients in both the public and private sectors, advocating for targeted policy, legislative, and programmatic reforms, securing government funding for critical programs, promoting global best practices, and fostering public-private partnerships. Skilled in cultivating relationships with government officials across the political spectrum, building consensus, and crafting targeted advocacy strategies that influence high-level decision-making to achieve maximum impact.',
	bullets: ['Comprehensive understanding of the Westminster system, including lobbying regulations and public policy process, with proven expertise in engaging the Canadian government to influence legislation and policy outcomes.', 'Strong personal interest in and knowledge of geopolitical, economic, and cultural dynamics across South Asia and the Middle East.', 'Multi-lingual: English (Native), Urdu (Full Working Proficiency), French & Arabic (Basic Working Proficiency).'],
};
