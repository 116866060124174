import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';
import { useCase as dcf } from './dcf';
import { useCase as audioVoting } from './audioVoting';
import { useCase as ballotFileSystem } from './ballotFileSystem';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'dcf',
		section: 'Dominion Voting Systems',
		content: dcf,
		tags: ['agility', 'developer experience'],
	},
	{
		id: 'audioVoting',
		section: 'Dominion Voting Systems',
		content: audioVoting,
		tags: ['agility'],
	},
	{
		id: 'ballotFileSystem',
		section: 'Dominion Voting Systems',
		content: ballotFileSystem,
		tags: ['agility'],
	},
];
