import * as React from 'react';
import { Props as LayoutProps } from './MariamCVLayout';
import { schema as educationSchema } from '../../Schema/MariamCVs/default/Education';
import { schema as professionalExperienceSchema } from '../../Schema/MariamCVs/default/ProfessionalExperienceSchemaNew';
import { schema as skillsSchema } from '../../Schema/MariamCVs/default/Skills';
import { schema as technologySchema } from '../../Schema/MariamCVs/default/Technologies';
import { schema as contactSchema } from '../../Schema/MariamCVs/default/ContactInfo';
import { schema as toolsSchema } from '../../Schema/MariamCVs/default/Tools';
import { schema as ideologiesSchema } from '../../Schema/MariamCVs/default/Ideologies';
import { schema as coreCompetenciesSchema } from '../../Schema/MariamCVs/default/Core Competencies';
import { schema as profileSchema } from '../../Schema/MariamCVs/default/AboutMe';
import { schema as affiliations } from '../../Schema/MariamCVs/default/Affiliations';

export type Props = {
	Layout: any;
};

export const MariamCVContainer = (props: Props) => {
	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			educationSchema: educationSchema,
			professionalExperienceSchema: professionalExperienceSchema,
			affiliations,
			aboutMeSchemas: {
				skillsSchema,
				technologySchema,
				contactSchema,
				toolsSchema,
				ideologiesSchema,
				coreCompetenciesSchema,
				profileSchema,
			},
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
