import { ProfessionalExperienceEntryType } from '../../../Types/ProfessionalExperienceLineType';
import { ProfessionalExperienceEntry, Props as ProfessionalExperienceEntryProps } from '../Layouts/ProfessionalExperienceEntry';
import * as React from 'react';

export const generateProfessionalExperienceEntries = (keywords: string, entries: Array<ProfessionalExperienceEntryType>) => {
	const returnValue = entries.map((theEntry: ProfessionalExperienceEntryType, index: number) => {
		const lineProps: ProfessionalExperienceEntryProps = {
			entry: theEntry,
			keywords: keywords,
			odd: index % 2 === 0,
		};
		const innerReturnValue = <ProfessionalExperienceEntry key={'professionalExperienceEntry_' + index} {...lineProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
