import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { TheHelperBees } from '../../Employment/TheHelperBees';

export const useCase: UseCaseType = {
	title: 'Triage Bot',
	image: 'https://assets.dear-ux.com/faizhirani/images/Triage Bot.png',
	employment: TheHelperBees,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["Every week, a member of the software team was designated to deal with issues and bugs encountered by our Operations Team. This 'Triage Developer' would have no left-over capacity to contribute to the Software Team's goals.", 'Although over 75% of Triages were recurring, the Triage Developer was often unfamiliar, and would seek assistance. The ensuing discussion in the #Triage Slack channel would be a repeat of a previous one.', 'As they dreaded Triage week, developer enthusiasm would decline as their turn approached. Also, new hires were held back from the rotation for at least 6 months due to its demanding nature.', 'To me, it seemed extremely inefficient to dump this much of our software resources into recurring issues and requests. I decided to find a way to streamline the process.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ["I created a Triage Bot using Slack's API and integration tools.", 'Every time an issue or request was raised, the Triage Bot:', ['searched the #Triage channel for previous threads about the same issue;', 'compiled all discussions in those threads; and', 'and responded in thread with this context.'], 'During development, I discovered that it was useful to introduce a pin in-thread scheme that developers could use to leave notes that would appear the next time around.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['Triage Bot had massive qualitative and quantitative effects to the demanding nature of Triage, and relationship between Software and Ops.', 'Quantitatively, it streamlined the over 75% of triages that were recurrences. Those issues were efficiently addressed by the Triage Developer without the need for intervention.', 'Consequently:', ["the less demanding triage load increased the Triage Developer's capacity to contribute the Software Team's goals to ~50%;", "the reduced attention demand of #Traige improved the Software Team's productivity; and", 'new hires could be immediately integrated into the triage rotation.'], 'Qualitatively, the angst of your turn on Triage coming up dissolved. People no longer felt a resigned dread when they were called upon do Triage.'],
		},
	],
};
