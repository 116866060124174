import { AffiliationLayout, Props as AffiliationProps } from '../Layouts/AffiliationLayout';
import { SchemaType as AffiliationSchema } from '../../../../../Schema/MariamCVs/default/Affiliations';
import React from 'react';

export const generationAffiliations = (affilations: AffiliationSchema[]) => {
	const returnValue = affilations.map((affiliation: AffiliationSchema) => {
		const outgoingProps: AffiliationProps = {
			affiliationSchema: affiliation,
		};
		const innerReturnValue = <AffiliationLayout {...outgoingProps} />;

		return innerReturnValue;
	});
	return returnValue;
};
