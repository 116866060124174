import { ProfessionalExperienceEntriesType } from '../../../Types/ProfessionalExperienceLineType';

import { schema as DearUX } from './Projects/DearUX';
import { schema as CookingBlog } from './Projects/CookingBlog';
import { schema as ModelMicroserviceStack } from './Projects/ModelMicroserviceStack';
import { schema as BlenderAssetCreation } from './Projects/BlenderAssetCreation';
import { schema as GJ } from './Projects/GJ';
import { schema as DeepLearning } from './Projects/UdemyDeepLearning';

export const schema: ProfessionalExperienceEntriesType = {
	title: 'Projects',
	entries: [DeepLearning, CookingBlog, DearUX, ModelMicroserviceStack, BlenderAssetCreation, GJ],
};
