import styled from 'styled-components';
import { cssVarColors } from '../../Constants';
import { mobileThreshold } from '../../Constants/layoutValues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from '@mui/material';

export const ProfessionalExperienceSectionWrapper = styled.div`
	padding-top: 1.5em;
	padding-left: 1rem;
	@media print {
		padding-top: 1em;
		padding-left: 0.25rem;
	}
`;

export const ProfessionalSectionEntriesWrapper = styled('div')`
	padding-top: 0.5rem;
`;

export const EntryWrapperEven = styled('div')`
	margin-left: -0.75rem;
	padding-left: 0.75rem;
	display: flex;
	flex-direction: row;
	page-break-inside: auto;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	:first-of-type {
		padding-top: 0;
	}

	:last-of-type {
		padding-bottom: 0;
	}

	@media (max-width: ${mobileThreshold}px) {
		flex-direction: column;
	}

	@media print {
		padding-bottom: 0.25rem;
		padding-top: 0.25rem;
		:first-of-type {
			padding-top: 0;
		}
		:last-of-type {
			padding-bottom: 0;
		}
	}
`;

export const EntryWrapperOdd = styled(EntryWrapperEven)`
	background-color: ${cssVarColors.highlightAlpha};
`;

export const DateColumnWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 150px;
	min-width: 180px;
	@media print {
		color: #001c4c;
		min-width: 70px;
		width: 70px;
	}
`;

export const DateWrapper = styled('div')`
	font-size: 0.8em;
	border-bottom: 3px ${cssVarColors.highlight} solid;
	height: fit-content;
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	@media print {
		border-bottom: 1.5px ${cssVarColors.highlight} solid;
		font-size: 0.7em;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-right: 2rem;
		width: fit-content;
	}
`;

export const EntryColumnHeadingWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	padding-left: 1rem;
	flex-grow: 1;
	@media print {
		padding-left: 7px;
	}
`;

export const EntryColumnHeadingNoLeftColumnWrapper = styled(EntryColumnHeadingWrapper)`
	padding-left: 0;
	@media print {
		padding-left: 0;
	}
`;

export const TitleLineWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const Title = styled('div')``;

export const Spacer = styled('div')`
	font-style: italic;

	&::after {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		content: ' | ';
		font-style: normal;
		@media print {
			padding-left: 0.5em;
			padding-right: 0.5em;
		}
	}
`;

export const Company = styled('div')`
	font-style: italic;
`;

export const Location = styled('div')`
	font-style: italic;
`;

export const DetailsWrapper = styled('div')`
	font-size: 0.9em;
	line-height: 1.6em;
	page-break-inside: auto;
	@media print {
		font-size: 0.8em;
	}
`;

export const UnorderedList = styled('ul')`
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	margin-top: 0;
	list-style-position: inside;
	padding-left: 1.25rem;
	padding-left: 0.25rem;
	@media print {
		page-break-inside: auto;
		padding-left: 8px;
	}
`;

export const TimespanWrapper = styled('div')``;

export const EntryContent = styled('li')`
	align-items: center;
	@media print {
		text-indent: -7px;
	}
`;

export const Bolt = styled(EntryContent)`
	list-style-type: none;
	:before {
		content: '\\f0e7';
		font-family: FontAwesome;
		font-size: 0.6rem;
		display: inline-block;
		margin-left: -2px;
		width: 1.2rem;
		color: ${cssVarColors.highlight};
		line-height: 0;
		@media print {
			text-indent: -7px;
			font-size: 0.25rem;
			margin-left: 7px;
			width: 0px;
		}
	}
`;

export const LengthWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const MajorAccomplishmentWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const EmployeeNumberWrapper = styled('div')`
	display: flex;
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const ResultWrapper = styled('div')`
	padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
	display: inline;
`;

export const ResultsLink = styled(Link)`
	display: inline;
	text-decoration: none !important;
	:hover {
		color: ${cssVarColors.highlight} !important;
	}
`;

export const EmploymentDetails = styled('div')`
	padding-right: 1rem;
	padding-top: 0rem;
	line-height: 1em;
	@media print {
		padding-right: 0.25rem;
	}
`;

export const DescriptionWrapper = styled('div')`
	display: flex;
	//padding-top: 0.25rem;
	font-weight: 100;
	font-size: 0.65em;
	padding-left: 0rem;
	font-style: italic;
`;

export const SkillsAcquiredWrapper = styled('div')`
	display: flex;
	font-weight: 100;
	font-size: 0.85em;
	padding-left: 0;
	//font-style: italic;
	line-height: 1.5;
	flex-direction: row;
	@media print {
		padding-top: 0.1em;
		font-size: 0.8em;
	}
`;

export const LanguagesWrapper = styled(SkillsAcquiredWrapper)`
	padding-top: 0;
`;

export const ToolsWrapper = styled(SkillsAcquiredWrapper)`
	padding-top: 0;
`;

export const TechnologiesWrapper = styled(SkillsAcquiredWrapper)`
	padding-top: 0;
`;

export const SkillsAcquiredTitle = styled('div')`
	font-family: montserratMedium;
	padding-right: 0.25rem;
`;

export const LanguagesTitleWrapper = styled(SkillsAcquiredTitle)``;

export const ToolsTitleWrapper = styled(SkillsAcquiredTitle)``;

export const TechnologyTitleWrapper = styled(SkillsAcquiredTitle)``;
