import * as React from 'react';
import { ContactWrapper, EmailAddressWrapper, HeadingWrapper, LinkedInWrapper, NameWrapper, PhoneNumberWrapper } from './HeadingStyles';
import { language } from './HeadingLanguage';
import { SchemaType as ContactSchemaType } from '../../../../Schema/CVs/default/ContactInfo';

export type Props = {
	contactSchema: ContactSchemaType;
};

export const HeadingLayout = (props: Props) => {
	const render = () => {
		const returnValue = (
			<HeadingWrapper>
				<NameWrapper>Mariam Razi</NameWrapper>
				<ContactWrapper>
					<PhoneNumberWrapper>{props.contactSchema.lines[0].text}</PhoneNumberWrapper>
					<EmailAddressWrapper>{props.contactSchema.lines[1].text}</EmailAddressWrapper>
					<LinkedInWrapper>{props.contactSchema.lines[3].href}</LinkedInWrapper>
				</ContactWrapper>
			</HeadingWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
