import * as React from 'react';
import { ToolsTitleWrapper, ToolsWrapper } from '../ProfessionalExperienceSectionStyles';
import { language } from '../ProfessionalExperienceSectionLanguage';

export type Props = {
	tools: Array<string>;
};

export const ToolsLayout = (props: Props) => {
	const tools = generateTools(props.tools.sort());
	const render = () => {
		const returnValue =
			props.tools.length === 0 ? null : (
				<ToolsWrapper>
					<ToolsTitleWrapper>{language.toolsUsedHeader}:</ToolsTitleWrapper>
					{tools}
				</ToolsWrapper>
			);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const generateTools = (tools: Array<string>) => {
	const returnValue = tools.join(', ');
	return returnValue;
};
