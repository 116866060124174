import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~4 years',
	timespan: '2014 -  2018',
	title: 'Founder & Chief Technology Officer',
	technologies: ['LAMP', 'NodeJS (Express)', 'SQL (OLTP)'],
	tools: ['AWS'],
	languages: ['Javascript', 'PHP', 'Java'],
	company: 'Bamba',
	description: 'A company that provided tools for data collection in emerging markets',
	location: 'Toronto, Ontario',
	results: 'Jan 2018 - Acquired by {Maximeyes Group,  https://maximeyesgroup.me}',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Built network architecture supporting streams from 500k+ with peak of 2,000 QPS, ensuring high availability and scalability',
			// entry: 'Built architecture handling data streams from 500k+ users across multiple channels with peak of 2 000 QPS',
		},
		{
			showMeOn: ['all'],
			entry: 'Invented viral referral-incentive algorithm yielding 50k+ participants from a seed of 20 in 24 hours driving user acquisition',
		},
		{
			showMeOn: ['all'],
			entry: 'Developed self-serve portal enabling clients to design, launch, monitor, and report on data collection, reducing manual intervention and improving client autonomy',
		},
		{
			showMeOn: ['all'],
			entry: 'Improved data integrity by 15% by developing automated data cleansing and fraud detection algorithms',
		},
		{
			showMeOn: ['all'],
			entry: 'Attended TechStars, Austin (Winter 2016), absorbing mentorship and strategic guidance that fueled company growth',
		},
	],
};
