import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../../../../Constants/layoutValues';

export const AffiliationWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	@media print {
		padding-top: 0.25em;
		padding-bottom: 0.25em;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-bottom: 0.25rem;
		flex-direction: column;
	}
`;

export const Title = styled('div')`
	font-family: montserratMedium;
`;

export const Org = styled('div')`
	padding-left: 0.5rem;
	font-style: italic;
	@media print {
		padding-left: 0.2rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
	}
`;

export const Timespan = styled('div')`
	padding-left: 0.5rem;
	@media print {
		padding-left: 0.2rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
	}
`;
