import * as React from 'react';
import { ResultsLink, ResultWrapper } from '../ProfessionalExperienceSectionStyles';
import { Link } from '@mui/material';

export type Props = {
	results: string | null;
};

export const ResultLayout = (props: Props) => {
	const render = () => {
		const internalJSX = props.results ? replaceLinkSyntax(props.results) : null;
		const resultJSX = <ResultWrapper>{internalJSX}</ResultWrapper>;
		const returnValue = props.results ? resultJSX : null;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const replaceLinkSyntax = (str: string) => {
	const regex = /\{([^,]+),\s*(https?:\/\/[^\}]+)\}/g;
	const parts = [];
	let lastIndex = 0;
	let match;

	// Loop through all matches and collect JSX or string parts
	while ((match = regex.exec(str)) !== null) {
		const [fullMatch, linkText, linkHref] = match;

		// Push any text before the match
		if (match.index > lastIndex) {
			parts.push(str.slice(lastIndex, match.index));
		}

		// Push the <Link> component for the current match
		parts.push(
			<ResultsLink target="_blank" key={match.index} href={linkHref}>
				{linkText}
			</ResultsLink>
		);

		// Update lastIndex to the end of the current match
		lastIndex = regex.lastIndex;
	}

	// Push any remaining text after the last match
	if (lastIndex < str.length) {
		parts.push(str.slice(lastIndex));
	}

	return parts;
};
