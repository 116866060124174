import * as React from 'react';
import { HighlightContentWrapper, HighlightsPanelWrapper } from './HighlightsPanelStyles';
import { language } from './HighlightsPanelLanguage';
import { Props as SectionTitleProps, SectionTitleLayout } from '../SectionTitle/SectionTitleLayout';
import { generateHighlights } from './helpers/generateHighlights';
import { CVHighlightType } from '../../Types/CVHighlightsType';

export type Props = {
	title: string;
	highlights: CVHighlightType[];
};

export const HighlightsPanelLayout = (props: Props) => {
	const render = () => {
		const backgroundTitleProps: SectionTitleProps = {
			text: props.title,
			fullWidth: true,
		};
		const highlightsJSX = generateHighlights(props.highlights);
		const returnValue = (
			<HighlightsPanelWrapper>
				<SectionTitleLayout {...backgroundTitleProps} />
				<HighlightContentWrapper>{highlightsJSX}</HighlightContentWrapper>
			</HighlightsPanelWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
