import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';
import { useCase as mobileAppDXRestructure } from './mobileAppDXRestructure';
import { useCase as partnerLaunchAutomation } from './partnerLaunchAutomation';
import { useCase as partnerConfig } from './partnerConfig';
import { useCase as triageBot } from './triageBot';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'mobileAppDXRestructure',
		section: 'The Helper Bees',
		content: mobileAppDXRestructure,
		tags: ['developer experience'],
	},
	{
		id: 'launchAutomation',
		section: 'The Helper Bees',
		content: partnerLaunchAutomation,
		tags: ['developer experience'],
	},
	{
		id: 'partnerConfig',
		section: 'The Helper Bees',
		content: partnerConfig,
		tags: ['developer experience', 'agility'],
	},
	{
		id: 'helperBeesTriageBot',
		section: 'The Helper Bees',
		content: triageBot,
		tags: ['developer experience'],
	},
];
