import React from 'react';
import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~3 years',
	timespan: '2008 - 2011',
	title: 'Supervisor / Articling Student',
	company: 'Community Legal Services',
	description: 'A legal clinic overseen by lawyers, but staffed by Law Students',
	location: 'London, Ontario',
	technologies: [],
	tools: [],
	languages: [],
	details: [
		{
			showMeOn: ['all'],
			autonomous: true,
			entry: <>Reduced an 8-hour weekly task to 10 minutes for 11 peers by reverse-engineering and repurposing undocumented software</>,
		},
		{
			showMeOn: ['none'],
			entry: 'Scripted, directed, and produced a series of instructional videos for a litigation course',
		},
	],
};
