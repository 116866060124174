export type SchemaType = {
	title: string;
	lines: Array<{
		icon: any;
		text: string | Array<string>;
		href?: string;
	}>;
};

export const schema: SchemaType = {
	title: 'Contact Info',
	lines: [
		{
			icon: ['fas', 'phone'],
			text: '1 (416) 876-6471',
		},
		{
			icon: ['fas', 'envelope'],
			text: 'mariamrazi4@gmail.com',
		},
		{
			icon: ['fas', 'location-dot'],
			text: ['Toronto, Ontario', 'Canada'],
		},
		{
			icon: ['fab', 'linkedin'],
			text: 'LinkedIn',
			href: 'https://www.linkedin.com/in/mariamrazi/',
		},
		{
			icon: ['fas', 'briefcase'],
			text: 'Portfolio',
			href: 'https://www.faizhirani.com/portfolio',
		},
	],
};
