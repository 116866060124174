import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { TheHelperBees } from '../../Employment/TheHelperBees';

export const useCase: UseCaseType = {
	title: 'Partner Launch Automation',
	image: 'https://assets.dear-ux.com/faizhirani/images/Partner Launch Automation.png',
	employment: TheHelperBees,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["To support our Partners, the Helper Bees' software team had designed a Partner App through which referrals could be submitted to our Operations team's App. Our Operations team then 'opened' the Referral, and undertook real world action. While the Referral was open, information was communicated back and forth between the Partner and Ops Apps.", 'Shortly after being hired, I observed the process of on-boarding a new Partner outlined below:', ['the target length of the process was 2 weeks (and this was optimistic)', 'a veteran developer was appointed onboarding-czar on a full-time basis', 'intermittent focus was required from at least a dozen others', 'bi-daily progress meetings included multiple stakeholders from different departments', 'regression testing was required in every meeting to ensure no inadvertent bugs arose', 'the process was fraught with types of things that humans are bound to screw up', 'the process was frail and extremely error prone when executed by humans'], 'I advised the CTO that there was no way to scale the company with this on-boarding process,  and that I had nightmares of being appointed the onboarding czar. I was given leave to fix this.'],
		},
		{
			sectionTitle: 'Solution',
			columns: 3,
			paras: ['I created a software solution to on-board Partners.', 'A simple initialization file, once populated, could be fed into the automation software. Based on the initialization file, the correct:', ['compute resources', 'databases', 'Django entries', 'Flask entries', 'ReactJS dashboards', 'CRM pipelines', 'admin users', 'periodic (celery) tasks', 'APIs and endpoints', 'information workflows,', 'and custom resources'], 'were created, and then automated tests were run.', 'If, at any point, an error was encountered, the automation process rolled back all changes, and terminated all created resources, such that the architecture reverted to the original state.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['It is difficult to overstate the impact of this automation on business operations, our ability to scale. and the general moral across the company.', 'The process of onboarding a new Partner was reduced from the two week czar-driven process to simple task that could be leisurely accomplished in an afternoon whilst enjoying a boba tea.  This is a 99.9983% reduction in the workload', 'From a moral perspective, the dread surrounding the daunting task of on-boarding a Partner in the Software and Operations departments completely dissolved. Onboarding a new Partner became a cause for celebaration... as it always should have been.'],
		},
	],
};
