import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const CvWrapper = styled.div`
	display: flex;
	flex-direction: row;
	@media screen {
		font-size: 1.15em;
		line-height: 1.6em;
		max-width: 1600px;
	}

	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	@media print {
		padding: 0;
	}
`;

export const BodySection = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const ProjectsBlurb = styled('div')`
	padding-top: 0.5rem;
	display: flex;
	max-width: 100%;
	padding-left: 5.75rem;
	padding-right: 5rem;
	@media print {
		padding-left: 24px;
		padding-right: 20px;
		padding-top: 0;
		//padding-top: 4em;
	}
`;
