import * as React from 'react';
import { BodySection, CvWrapper, ProjectsBlurb } from './cvStyles';
import { AboutSectionLayout, Props as AboutSectionProps } from '../../Components/AboutSection/AboutSectionLayout';
import { ProfessionalExperienceSectionLayout, Props as ProfessionalExperienceProps } from '../../Components/ProfessionalExperienceSection/ProfessionalExperienceSectionLayout';
import { EducationPanelLayout, Props as EducationPanelProps } from '../../Components/EducationPanel/EducationPanelLayout';
import { HighlightsPanelLayout, Props as HighlightsPanelProps } from '../../Components/HighlightsPanel/HighlightsPanelLayout';
import { cssVarColors } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { CvFilterType } from '../../Types/cvFilterType';
import { ProfessionalExperienceEntriesType } from '../../Types/ProfessionalExperienceLineType';

import { SchemaType as EducationSchemaType } from '../../Schema/CVs/default/Education';
import { SchemaType as SkillsSchemaType } from '../../Schema/CVs/default/Skills';
import { SchemaType as TechnologySchemaType } from '../../Schema/CVs/default/Technologies';
import { SchemaType as ContactSchemaType } from '../../Schema/CVs/default/ContactInfo';
import { SchemaType as ToolsSchemaType } from '../../Schema/CVs/default/Tools';
import { SchemaType as LanguagesSchemaType } from '../../Schema/CVs/default/Languages';
import { SchemaType as IdeologiesSchemaType } from '../../Schema/CVs/default/Ideologies';
import { SchemaType as CoreCompetenciesSchemaType } from '../../Schema/CVs/default/Core Competencies';
import { CVHighlightsType } from '../../Types/CVHighlightsType';
import { language } from './cvLanguage';

export type Props = {
	educationSchema: EducationSchemaType;
	projectsSchema: ProfessionalExperienceEntriesType;
	professionalExperienceSchema: ProfessionalExperienceEntriesType;
	highlightsSchema: CVHighlightsType;
	aboutMeSchemas: {
		skillsSchema: SkillsSchemaType;
		technologySchema: TechnologySchemaType;
		contactSchema: ContactSchemaType;
		toolsSchema: ToolsSchemaType;
		highlightedSchema: LanguagesSchemaType;
		languagesSchema: LanguagesSchemaType;
		ideologiesSchema: IdeologiesSchemaType;
		coreCompetenciesSchema: CoreCompetenciesSchemaType;
	};
};

export const CvLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		const rawKeywords = searchParams.get('keywords');
		const keywords = rawKeywords ? rawKeywords.split(',') : [];

		const educationProps: EducationPanelProps = {
			title: props.educationSchema.title,
			backgroundColor: cssVarColors.onSurface,
			textColor: '#fff',
			lines: props.educationSchema.lines,
		};
		const aboutSectionProps: AboutSectionProps = {
			keywords: keywords as CvFilterType,
			aboutMeSchemas: props.aboutMeSchemas,
		};
		const professionalExperienceProps: ProfessionalExperienceProps = {
			professionExperienceSchema: props.professionalExperienceSchema,
		};

		const highlightsPanelProps: HighlightsPanelProps = {
			title: props.highlightsSchema.title,
			highlights: props.highlightsSchema.lines,
		};

		const projectsPanelProps: ProfessionalExperienceProps = {
			professionExperienceSchema: props.projectsSchema,
		};

		const returnValue = (
			<CvWrapper>
				<AboutSectionLayout {...aboutSectionProps} />
				<BodySection>
					<HighlightsPanelLayout {...highlightsPanelProps} />
					<ProfessionalExperienceSectionLayout {...professionalExperienceProps} />
					<EducationPanelLayout {...educationProps} />
					<ProjectsBlurb>{language.projectsBlurb}</ProjectsBlurb>
					<ProfessionalExperienceSectionLayout {...projectsPanelProps} />
				</BodySection>
			</CvWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
