import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Highlighted',
	lines: [
		{
			entry: 'C',
			showOn: ['all'],
		},
		{
			entry: 'C++',
			showOn: ['all'],
		},
		{
			entry: 'Python',
			showOn: ['all'],
		},
		{
			entry: 'AWS',
			showOn: ['all'],
		},
		{
			entry: 'Build Tools (AWS CodeBuild, CodePipeline, CodeDeploy, CodeCommit, CloudFormation)',
			showOn: ['all'],
		},
		{
			entry: 'GCP',
			showOn: ['all'],
		},
		{
			entry: 'Microsoft Azure',
			showOn: ['all'],
		},
		{
			entry: 'CircleCI',
			showOn: ['all'],
		},
		{
			entry: 'CI/CD Pipelines',
			showOn: ['all'],
		},
		{
			entry: 'Developer Experience (DX)',
			showOn: ['all'],
		},
	],
};
