import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../../../Constants/layoutValues';
import { cssVarColors } from '../../../../Constants';

export const EducationWrapper = styled('div')`
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	border-bottom: 1px solid ${cssVarColors.surface};
	@media print {
		padding-bottom: 0rem;
		margin-bottom: 0.25rem;
	}
`;

export const EducationContentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	font-size: 1em;
	line-height: 1.4em;
	padding-bottom: 1rem;
	@media print {
		line-height: 1.5em;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-left: 0;
		margin-right: 0;
	}
`;
