import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: 'Ingredient Dispense Accuracy',
	image: 'https://assets.dear-ux.com/faizhirani/images/Canister Estimation.png',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['At Chowbotics, we sold Food Robots to food service companies, who were responsible for stocking and maintaining them. Our culinary team standardized serving sizes, and the Mechanical Engineering team developed algorithms to ensure accurate dispensing.', 'However, as we scaled and onboarded more diverse food service partners, the variations in ingredient handling rendered our dispensing algorithms ineffective, casing significant discrepancies in the target and dispensed weights. This was more pronounced for ingredients that varied greatly in preparation, such as bacon.', 'Our CEO suggested pressuring partners to follow our strict bacon preparation standards, but I knew this approach was unsustainable. In my experience, you must meet customers where they are, not force them to conform to rigid specifications.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['I implemented two solutions. First, I built a web app that visualized the target vs. actual dispense weights for ingredients across all robots, allowing the ‘Bacon Task Force’ to monitor discrepancies and investigate problem cases. The insights from this app enabled the Mechanical Engineering team to manually adjust dispensing algorithms using real-time data.', 'After observing how these adjustments were made, I automated the process. I developed a dynamic algorithm service that used a negative feedback loop to adjust dispensing algorithms based on real-time performance. The service continuously refined the algorithms until they were optimized for each robot’s local conditions, scaling across all 200 ingredients and 150 robots—something manual adjustments couldn’t achieve.\n'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The first solution provided Mechanical Engineers with the visibility needed to make informed adjustments in the field. By adding a local override feature, we enabled robots to use custom algorithms on a per-ingredient basis.', 'The second solution had a much greater impact. The dynamic algorithm service enabled each Robot to converge on the optimum dispensing algorithm over time, improving accuracy at scale. If conditions changed, such as a new employee preparing ingredients differently, the algorithm would automatically adjust to maintain optimal performance.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['Chowbotics’ distributed, event-driven, and decoupled network infrastructure — which I had designed for agility — made it easy to create a service that maintained real-time of dispenses, and refined the dispense algorithms based on the local data.'],
		},
	],
};
