import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	noLeftColumn: true,
	skillsAcquired: ['OpenAI Assistants'],
	showMeOn: ['all'],
	length: '',
	timespan: '',
	title: "Faiz's Cooking App",
	technologies: ['NoSQL', 'React', 'NodeJS', 'React Native', 'Open AI', 'Stable Diffusion'],
	tools: ['AWS'],
	languages: ['Javascript', 'TypeScript', 'Swift', 'Kotlin'],
	company: '2023 - Present',
	description: '',
	location: '',
	results: '',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Developed a cooking app using OpenAI to automate image recognition and organization for 150+ dishes, eliminating the need for manual tagging and categorization by associating images with the correct recipes',
		},
		{
			showMeOn: ['all'],
			entry: 'Designed assistants to guide users through recipe execution, enhancing the overall user experience with step-by-step support',
		},
		{
			showMeOn: ['all'],
			entry: 'Gained a deep understanding of how neural networks process, weight, and consolidate information, using the app as a foil for deep dives into AI and machine learning — knowledge that I now apply in problem-solving across professional and personal projects',
		},
	],
};
