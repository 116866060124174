import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../../../Constants';

export const ProfileWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
	border-bottom: 1px solid ${cssVarColors.onSurface};
	@media print {
		padding-top: 1rem;
		padding-bottom: 0.25rem;
		margin-bottom: 0.25rem;
		margin-left: 0;
		margin-right: 0;
	}
`;

export const IntroWrapper = styled('div')`
	@media print {
		line-height: 1.6;
	}
`;

export const SpecialCompetenciesWrapper = styled('div')`
	padding-top: 1rem;
	@media print {
		padding-top: 0.5rem;
		line-height: 1.6;
	}
`;

export const SpecialCompetenciesHeadingWrapper = styled('div')`
	font-family: montserratSemiBold;
`;

export const SpecialCompetenciesBulletsWrapper = styled('div')``;

export const BulletsWrapper = styled('ul')`
	margin-top: 0;
	padding-top: 0.25rem;
`;

export const BulletWrapper = styled('li')``;
