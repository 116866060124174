import { UseCaseWrapperType } from '../../../../Types/Portfolio/UseCaseWrapperType';
import { useCase as chowboticsInfrastructure } from './chowboticsInfrastructure';
import { useCase as notificationStreams } from './notificationStreams';
import { useCase as fleetCommand } from './fleetCommand';
import { useCase as gCodeEfficacy } from './gCodeEfficacy';
import { useCase as bowlCounterUseCase } from './bowlCounter';
import { useCase as bowlAutocompleteUseCase } from './bowlAutocomplete';
import { useCase as languageAbstraction } from './languageAbstraction';
import { useCase as canisterAnalysis } from './canisterEstimation';

export const useCases: UseCaseWrapperType[] = [
	{
		id: 'chowboticsInfrastructure',
		section: 'Chowbotics',
		content: chowboticsInfrastructure,
		tags: ['infrastructure'],
	},
	{
		id: 'chowboticsNotificationStreams',
		section: 'Chowbotics',
		content: notificationStreams,
		tags: ['agility'],
	},
	{
		id: 'chowboticsFleetCommand',
		section: 'Chowbotics',
		content: fleetCommand,
		tags: ['agility'],
	},
	{
		id: 'gCodeEfficacy',
		section: 'Chowbotics',
		content: gCodeEfficacy,
		tags: ['agility'],
	},
	// {
	// 	id: 'chowboticsRepeatCustomers',
	// 	section: 'Chowbotics',
	// 	content: repeatCustomers,
	// },
	{
		id: 'chowboticsBowlCounter',
		section: 'Chowbotics',
		content: bowlCounterUseCase,
		tags: ['agility'],
	},
	{
		id: 'chowboticsBowlAutocomplete',
		section: 'Chowbotics',
		content: bowlAutocompleteUseCase,
		tags: ['agility'],
	},
	{
		id: 'languageAbstraction',
		section: 'Chowbotics',
		content: languageAbstraction,
		tags: ['agility'],
	},
	{
		id: 'chowboticCanisterEstimation',
		section: 'Chowbotics',
		content: canisterAnalysis,
		tags: ['agility'],
	},
];
