import * as React from 'react';
import { AffiliationsWrapper, ContentWrapper } from './AffiliationsStyles';
import { language } from './AffiliationsLanguage';
import { SectionTitleLayout, Props as SectionTitleProps } from '../SectionTitle/SectionTitleLayout';
import { SchemaType as AffiliationsSchema } from '../../../../Schema/MariamCVs/default/Affiliations';
import { generationAffiliations } from './helpers/generateAffiliations';

export type Props = {
	affiliations: AffiliationsSchema[];
};

export const AffiliationsLayout = (props: Props) => {
	const render = () => {
		const sectionTitleProps: SectionTitleProps = {
			text: language.sectionTitle,
		};

		const affiliationJSX = generationAffiliations(props.affiliations);
		const returnValue = (
			<AffiliationsWrapper>
				<SectionTitleLayout {...sectionTitleProps} />
				<ContentWrapper>{affiliationJSX}</ContentWrapper>
			</AffiliationsWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
