import * as React from 'react';
import { DateWrapper, UnorderedList, EntryContent, EntryColumnHeadingWrapper, DateColumnWrapper, DetailsWrapper, EntryWrapperEven, EntryWrapperOdd, EmploymentDetails, TimespanWrapper, Bolt, EntryColumnHeadingNoLeftColumnWrapper } from '../ProfessionalExperienceSectionStyles';
import { ProfessionalExperienceEntryType, ProfessionalExperienceLineItemType } from '../../../Types/ProfessionalExperienceLineType';
import { EmployeeNumberLayout, Props as EmployeeNumberProps } from './EmployeeNumberLayout';
import { MajorAccomplishmentLayout, Props as MajorAccomplishmentProps } from './MajorAccomplishmentLayout';
import { ResultLayout, Props as ResultProps } from './ResultLayout';
import { LengthLayout, Props as LengthProps } from './LengthLayout';
import { DescriptionLayout, Props as DescriptionProps } from './DescriptionLayout';
import { ToolsLayout, Props as ToolsProps } from './ToolsLayout';
import { LanguagesLayout, Props as LanguagesProps } from './LanguagesLayout';
import { TitleLine, Props as TitleLineProps } from './TitleLine';
import { SkillsAcquired, Props as SkillsAcquiredProps } from './SkillsAcquired';

export type Props = {
	entry: ProfessionalExperienceEntryType;
	keywords: string;
	odd: boolean;
};

export const ProfessionalExperienceEntry = (props: Props) => {
	const render = () => {
		const emptyJSX = null;
		const EntryWrapper = props.odd ? EntryWrapperEven : EntryWrapperOdd;
		const lengthProps: LengthProps = {
			length: props.entry.length,
		};
		const employeeNumberProps: EmployeeNumberProps = {
			number: props.entry.employeeNumber ? props.entry.employeeNumber.number : null,
			type: props.entry.employeeNumber ? props.entry.employeeNumber.type : null,
		};

		const majorAccomplishmentProps: MajorAccomplishmentProps = {
			majorAccomplishments: props.entry.majorAccomplishment ? props.entry.majorAccomplishment : null,
		};

		const resultsProps: ResultProps = {
			results: props.entry.results ? props.entry.results : null,
		};
		const descriptionProps: DescriptionProps = {
			description: props.entry.description,
		};

		const toolsAndTechProps: ToolsProps = {
			tools: [...props.entry.tools, ...props.entry.technologies],
		};

		const languagesProps: LanguagesProps = {
			languages: props.entry.languages,
		};

		const skillsAcquiredProps: SkillsAcquiredProps = {
			skillsAcquired: props.entry.skillsAcquired ? props.entry.skillsAcquired : [],
		};

		const resultJSX = <ResultLayout {...resultsProps} />;
		const employeeJSX = <EmployeeNumberLayout {...employeeNumberProps} />;
		const majorAccomplishmentJSX = <MajorAccomplishmentLayout {...majorAccomplishmentProps} />;
		const descriptionJSX = <DescriptionLayout {...descriptionProps} />;
		const lengthJSX = <LengthLayout {...lengthProps} />;
		const toolsJSX = <ToolsLayout {...toolsAndTechProps} />;
		const skillsAcquired = <SkillsAcquired {...skillsAcquiredProps} />;
		const languagesJSX = <LanguagesLayout {...languagesProps} />;
		const timespan = props.entry.timespan.split(';').map((entry: string, index: number) => <TimespanWrapper key={'timespan_' + index}>{entry}</TimespanWrapper>);
		const leftColumnJSX = props.entry.noLeftColumn ? null : (
			<DateColumnWrapper>
				<DateWrapper>{timespan}</DateWrapper>
				<EmploymentDetails>
					{descriptionJSX}
					{lengthJSX}
					{employeeJSX}
					{majorAccomplishmentJSX}
					{resultJSX}
				</EmploymentDetails>
			</DateColumnWrapper>
		);
		const EntryColumnWrapper = props.entry.noLeftColumn ? EntryColumnHeadingNoLeftColumnWrapper : EntryColumnHeadingWrapper;
		const titleLineProps: TitleLineProps = {
			entry: props.entry,
		};
		const entryJSX = (
			<EntryWrapper>
				{leftColumnJSX}
				<EntryColumnWrapper>
					<TitleLine {...titleLineProps} />
					{skillsAcquired}
					{languagesJSX}
					{/*{technologiesJSX}*/}
					{toolsJSX}
					<DetailsWrapper>
						<UnorderedList>{generateDetails(props.entry.details, props.keywords)}</UnorderedList>
					</DetailsWrapper>
				</EntryColumnWrapper>
			</EntryWrapper>
		);
		const returnValue = shouldIShow(props.entry.showMeOn, undefined, props.keywords) ? entryJSX : emptyJSX;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export const shouldIShow = (showMeOn: Array<string>, omitMeOn: Array<string> | undefined, keywords: string) => {
	let returnValue = false;
	if (showMeOn.indexOf('all') !== -1) returnValue = true;
	showMeOn.map((show: string) => {
		if (keywords.indexOf(show) !== -1) returnValue = true;
	});
	if (showMeOn.indexOf(keywords) !== -1) returnValue = true;
	if (omitMeOn && omitMeOn.indexOf(keywords) !== -1) returnValue = false;
	return returnValue;
};

export const generateDetails = (details: Array<ProfessionalExperienceLineItemType>, keywords: string) => {
	const returnValue = details.map((lineItem: ProfessionalExperienceLineItemType, index: number) => {
		const shouldIShowBoolean = shouldIShow(lineItem.showMeOn, lineItem.omitMeOn, keywords);
		let conditionalReturnValue: any = null;
		const EntryContentWrapper = !lineItem.autonomous ? EntryContent : Bolt;
		if (shouldIShowBoolean) {
			const entryJSX = <EntryContentWrapper key={'professionalExperienceEntryLineItem_' + index}>{lineItem.entry}</EntryContentWrapper>;
			conditionalReturnValue = entryJSX;
			if (lineItem.children) {
				const childrenJSX = <UnorderedList>{generateDetails(lineItem.children, keywords)}</UnorderedList>;
				conditionalReturnValue = [entryJSX, childrenJSX];
			}
		}
		const innerReturnValue = shouldIShowBoolean ? conditionalReturnValue : null;
		return innerReturnValue;
	});
	return returnValue;
};
