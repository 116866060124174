import styled from 'styled-components';
import { mobileThreshold } from '../../Constants/layoutValues';

export const EducationPanelWrapper = styled.div`
	flex: 1;
	padding-top: 1.5rem;
	padding-left: 0.75rem;
	:first-of-type {
		padding-left: 0;
	}
	height: fit-content;
	max-height: fit-content;
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-right: 0;
	}
	@media print {
		padding-top: 12.5em;
		padding-left: 0.25rem;
	}
`;

export const EducationContentWrapper = styled('div')`
	padding-top: 0.5rem;
	display: flex;
	flex-direction: column;
	font-size: 1em;
	line-height: 1.4em;
	padding-bottom: 1rem;
	@media print {
		margin-top: -0.25rem;
		line-height: 1.5em;
		padding-bottom: 0.25rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const EducationItemWrapper = styled('div')`
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	display: flex;
	flex-direction: row;
	@media print {
		padding-top: 0.1em;
		padding-bottom: 0.1em;
	}
`;

export const EducationDegreeWrapper = styled('div')`
	font-family: montserratMedium;
	padding-right: 0.25rem;
`;

export const EducationUniversityWrapper = styled('div')`
	padding-right: 0.25rem;
`;

export const EducationLocationWrapper = styled('div')`
	padding-right: 0.25rem;
`;

export const EducationTimespanWrapper = styled('div')`
	padding-right: 0.25rem;
`;
