import * as React from 'react';
import { ProfessionalExperienceSectionWrapper, ProfessionalSectionEntriesWrapper } from './ProfessionalExperienceSectionStyles';
import { language } from './ProfessionalExperienceSectionLanguage';
import { Props as SectionTitleProps, SectionTitleLayout } from '../SectionTitle/SectionTitleLayout';
import { ProfessionalExperienceEntriesType } from '../../Types/ProfessionalExperienceLineType';
import { useSearchParams } from 'react-router-dom';
import { generateProfessionalExperienceEntries } from './helpers/generateProfessionalExperienceEntries';

export type Props = {
	professionExperienceSchema: ProfessionalExperienceEntriesType;
};

export const ProfessionalExperienceSectionLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		const rawKeywords = searchParams.get('keywords');
		const keywords: string = rawKeywords ? (searchParams.get('keywords') as string) : '';
		const sectionTitleProps: SectionTitleProps = {
			text: props.professionExperienceSchema.title,
			fullWidth: true,
		};
		const professionsExperienceEntries = generateProfessionalExperienceEntries(keywords, props.professionExperienceSchema.entries);

		const returnValue = (
			<ProfessionalExperienceSectionWrapper>
				<SectionTitleLayout {...sectionTitleProps} />
				<ProfessionalSectionEntriesWrapper>{professionsExperienceEntries}</ProfessionalSectionEntriesWrapper>
			</ProfessionalExperienceSectionWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
