import * as React from 'react';
import { Props as LayoutProps } from './cvLayout';
import { schema as skillsSchema } from '../../Schema/CVs/default/Skills';
import { schema as technologySchema } from '../../Schema/CVs/default/Technologies';
import { schema as contactSchema } from '../../Schema/CVs/default/ContactInfo';
import { schema as toolsSchema } from '../../Schema/CVs/default/Tools';
import { schema as languagesSchema } from '../../Schema/CVs/default/Languages';
import { schema as ideologiesSchema } from '../../Schema/CVs/default/Ideologies';
import { schema as coreCompetenciesSchema } from '../../Schema/CVs/default/Core Competencies';
import { schema as professionalExperienceSchema } from '../../Schema/CVs/default/ProfessionalExperienceSchema';
import { schema as educationSchema } from '../../Schema/CVs/default/Education';
import { schema as projectsSchema } from '../../Schema/CVs/default/Projects';
import { schema as highlightsSchema } from '../../Schema/CVs/default/CVHighlights';

export type Props = {
	Layout: any;
};

export const CvContainer = (props: Props) => {
	const render = () => {
		const Layout = props.Layout;
		const outgoingProps: LayoutProps = {
			projectsSchema: projectsSchema,
			educationSchema: educationSchema,
			professionalExperienceSchema: professionalExperienceSchema,
			highlightsSchema,
			aboutMeSchemas: {
				languagesSchema,
				skillsSchema,
				technologySchema,
				contactSchema,
				toolsSchema,
				ideologiesSchema,
				coreCompetenciesSchema,
			},
		};
		const returnValue = <Layout {...outgoingProps} />;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
