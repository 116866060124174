import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../../../Constants';
import { mobileThreshold } from '../../../../Constants/layoutValues';

export const HeadingWrapper = styled('div')`
	display: flex;
	background-color: ${cssVarColors.surfaceAlternate};
	width: 100%;
	color: #fff;
	flex-direction: column;
	align-items: center;
	padding-bottom: 2rem;
	padding-top: 2rem;
	@media print {
		padding-bottom: 1.25rem;
		padding-top: 2rem;
		background-color: ${cssVarColors.surfaceAlternate};
	}
`;

export const NameWrapper = styled('div')`
	font-size: 3rem;
	text-transform: uppercase;
	padding-bottom: 1rem;
	@media print {
		font-size: 2rem;
		padding-bottom: 1rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		font-size: 2.5rem;
	}
`;

export const ContactWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	padding-top: 0.5rem;
	@media (max-width: ${mobileThreshold}px) {
		width: 100%;
		align-items: flex-start;
		font-size: 0.9rem;
		flex-direction: column;
	}
`;

export const PhoneNumberWrapper = styled('div')`
	padding-left: 1rem;
	padding-right: 1rem;
	border-right: 0.5px solid #fff;
	@media (max-width: ${mobileThreshold}px) {
		border-right: 0;
	}
`;

export const EmailAddressWrapper = styled('div')`
	padding-left: 1rem;
	padding-right: 1rem;
	border-left: 0.5px solid #fff;
	border-right: 0.5px solid #fff;
	@media (max-width: ${mobileThreshold}px) {
		border-right: 0;
		border-left: 0;
	}
`;

export const LinkedInWrapper = styled('div')`
	padding-left: 1rem;
	padding-right: 1rem;
	border-left: 0.5px solid #fff;
	@media (max-width: ${mobileThreshold}px) {
		border-left: 0;
	}
`;
