import { TechnologyType } from '../../../Types/TechnologyType';
import { ToolsType } from '../../../Types/ToolsType';

export type SchemaType = {
	title: string;
	lines: Array<ToolsType>;
};

export const schema: SchemaType = {
	title: 'Languages',
	lines: [
		// {
		// 	entry: 'PHP',
		// 	showOn: ['all'],
		// },
		{
			entry: 'Java',
			showOn: ['all'],
		},
		{
			entry: 'C++',
			showOn: ['all'],
		},
		{
			entry: 'C',
			showOn: ['all'],
		},
		{
			entry: 'C#',
			showOn: ['all'],
		},
		{
			entry: 'JavaScript',
			showOn: ['all'],
		},
		{
			entry: 'Go',
			showOn: ['all'],
		},
		{
			entry: 'TypeScript',
			showOn: ['all'],
		},
		{
			entry: 'Python',
			showOn: ['all'],
		},
		{
			entry: 'SQL',
			showOn: ['all'],
		},
		{
			entry: 'PHP',
			showOn: ['all'],
		},
		// {
		// 	entry: 'Apex',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Kotlin',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Swift',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'VB.NET',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Matlab',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Perl',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Bash',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Assembly',
		// 	showOn: ['all'],
		// },
		// {
		// 	entry: 'Ruby',
		// 	showOn: ['all'],
		// },
	],
};
