import React from 'react';
import { CVHighlightsType } from '../../../Types/CVHighlightsType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// export const schema: CVHighlightsType = {
// 	title: 'Career Consistencies',
// 	lines: [
// 		{
// 			date: '',
// 			entry: 'Amplified team efficiency by ruthlessly hunting hidden friction points and stamping them out',
// 		},
// 		{
// 			date: '',
// 			entry: "Improved user satisfaction and retention by relentlessly prioritizing UX that moulds to the users natural behaviour in pursuit of their goal",
// 		},
// 		{
// 			date: '',
// 			entry: 'Developed delightful solutions by understanding that the resolution with which the problem is understood is proportional to the impact of the solution delivered',
// 		},
// 		{
// 			date: '',
// 			entry: "Ensured a high level of cross-departmental synergy by demanding clarity of communication in collaborative settings",
// 		},
// 		{
// 			date: '',
// 			entry: 'Prioritizing agility in all aspects such that the company is poised to refine its offerings in response to its reception in the market',
// 		},
// 		{
// 			date: '',
// 			entry: 'Delivered highly adaptable code anticipating that the solution delivered will be adjacent to the solution optimal',
// 		},
// 	],
// };

export const schema: CVHighlightsType = {
	title: 'Career Consistencies',
	lines: [
		{
			date: '',
			entry: '*Prioritized agility* to position the company for rapid refinement of its offerings in response to market reception',
		},
		{
			date: '',
			entry: '*Amplified team efficiency* by ruthlessly hunting hidden friction points and stamping them out to streamline workflows',
		},
		{
			date: '',
			entry: "*Boosted user satisfaction and retention* by relentlessly prioritizing UX that naturally aligns with users' behavior as they pursue their goals",
		},
		{
			date: '',
			entry: '*Developed impactful solutions* by focusing on accurately identifying problems in high resolution, making solution design straightforward',
		},
		{
			date: '',
			entry: '*Fostered cross-departmental synergy* by encouraging clarity and precision in communication during collaboration',
		},
		{
			date: '',
			entry: '*Delivered highly adaptable code* with foresight, anticipating that the solution delivered would often be adjacent to the optimal solution',
		},
		{
			date: '',
			// entry: "Consistently identified and resolved gaps independently, driving improvements without external prompts",
			entry: '*Autonomously identified and resolved gaps* without external prompts, examples have been marked with faBolt throughout this resume',
			// entry: '*Providing impact in an autonomous way* by taking the initiative. Line items marked with icon below are examples of gaps I identified and resolved without being asked',
		},
	],
};

// you need to highlight intiiative and autonomy
