import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	skillsAcquired: ['Psychology of User Experience'],
	noLeftColumn: true,
	showMeOn: ['all'],
	length: '',
	timespan: '',
	title: 'Dear UX',
	technologies: ['NoSQL', 'React', 'NodeJS'],
	tools: ['AWS'],
	languages: ['Javascript', 'TypeScript'],
	company: '2023 - Present',
	description: '',
	location: '',
	results: '',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Created blog to chronicle and critique the emotional impact of digital experiences, focusing on quirks and frustrations of interaction with technology',
		},
		{
			showMeOn: ['all'],
			entry: 'Studied foundational UX texts learning to analyze interactions through principles of cognitive load, emotional friction, and user-centered design',
		},
		{
			showMeOn: ['all'],
			entry: 'Developed the ability to pinpoint the specific psychological and design principles behind emotional responses to UX, enabling a clearer understanding of how and why it impacts users in my professional and personal projects',
		},
	],
};
