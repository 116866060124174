import * as React from 'react';
import { HighlightItemWrapper, HighlightBolded, HighlightText, HighlightBulletWrapper, BoltBullet, BoltWrapper } from '../HighlightsPanelStyles';
import { language } from '../HighlightsPanelLanguage';
import { CVHighlightType } from '../../../Types/CVHighlightsType';

export type Props = {
	highlight: CVHighlightType;
	index: number;
};

export const HighlightItem = (props: Props) => {
	const render = () => {
		const returnValue = (
			<HighlightItemWrapper>
				<HighlightBulletWrapper>{parseHighlightedText(props.highlight.entry, props.index)}</HighlightBulletWrapper>
			</HighlightItemWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export function parseHighlightedText(input: string, parentIndex: number): (JSX.Element | string)[] {
	const parts = input.split(/(\*[^*]+\*|faBolt)/); // Split by text wrapped in asterisks or 'faBolt'

	return parts.map((part, index) => {
		const key = parentIndex + '_' + index;
		let returnValue = <HighlightText key={'regular_' + key}>{part}</HighlightText>;
		if (part === 'faBolt')
			returnValue = (
				<BoltWrapper key={'bolt_' + key}>
					<BoltBullet icon={['fas', 'bolt']} />
				</BoltWrapper>
			);
		else if (part.startsWith('*') && part.endsWith('*')) {
			const content = part.slice(1, -1); // Remove the asterisks
			returnValue = <HighlightBolded key={'bold_' + key}>{content}</HighlightBolded>;
		}
		return returnValue;
	});
}
