import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	timespan: '',
	length: '',
	noLeftColumn: true,
	title: 'Gridiron Junkies',
	company: '2001 - 2009',
	description: 'An American Football opinion website',
	location: 'Toronto, Ontario',
	technologies: ['LAMP', 'SQL (OLTP)'],
	languages: ['PHP', 'Javascript'],
	tools: [],
	skillsAcquired: ['PHP', 'SQL', 'Apache'],
	details: [
		{
			showMeOn: ['all'],
			entry: 'As punishment for competence, was unanimously voted to be Webmaster of an opinion-centered NFL Football website against my will',
		},
		{
			autonomous: true,
			showMeOn: ['all'],
			entry: 'Developed a Content Management System enabling article publishing and homepage customization to be used by contributors',
		},
		{
			showMeOn: ['all'],
			entry: 'In 2016, Al Ismaili (Contributor to GJ, CEO of Bamba) referred to it as "WordPress before WordPress"',
		},
	],
};
