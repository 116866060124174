import { styled } from '@mui/material/styles';
import { cssVarColors } from '../../../../Constants';
import { mobileThreshold } from '../../../../Constants/layoutValues';

export const SectionTitleWrapper = styled('div')`
	font-size: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${cssVarColors.highlight};
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-transform: uppercase;
	@media print {
		font-size: 1rem;
		padding-bottom: 1rem;
		width: 100%;
	}
	@media (max-width: ${mobileThreshold}px) {
		align-items: flex-start;
		justify-content: flex-start;
	}
`;
