import { styled } from '@mui/material/styles';
import { mobileThreshold } from '../../Constants/layoutValues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cssVarColors } from '../../Constants';

export const HighlightsPanelWrapper = styled('div')`
	flex: 1;
	padding-top: 1.5rem;
	padding-left: 0.75rem;
	padding-bottom: 0;
	height: fit-content;
	max-height: fit-content;
	@media (max-width: ${mobileThreshold}px) {
		padding-left: 0;
		padding-right: 0;
	}
	@media print {
		padding-top: 0;
		padding-left: 0.25rem;
	}
`;

export const HighlightContentWrapper = styled('ul')`
	display: flex;
	flex-direction: column;
	line-height: 1.4em;
	padding-bottom: 0rem;
	padding-left: 1.5rem;
	font-size: 0.85em;
	list-style-position: outside;
	margin-bottom: 0;
	margin-top: 0.5rem;

	@media print {
		margin-top: 0.25rem;
		line-height: 1.5em;
		padding-bottom: 0;
		padding-left: 1rem;
	}
	@media (max-width: ${mobileThreshold}px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const BoltWrapper = styled('div')`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-right: 0.05rem;
`;

export const BoltBullet = styled(FontAwesomeIcon)`
	font-size: 0.85rem;
	color: ${cssVarColors.highlight};
	@media print {
		color: ${cssVarColors.highlight};
		font-size: 0.25rem;
	}
`;

export const HighlightItemWrapper = styled('li')`
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	flex-direction: row;
	text-indent: 0px;
	@media print {
		text-indent: -5px;
		padding-top: 0.1em;
		padding-bottom: 0.1em;
	}
`;

export const HighlightBulletWrapper = styled('div')``;

export const HighlightBolded = styled('div')`
	font-family: montserratMedium;
	padding-right: 1.25rme;
	display: inline;
`;

export const HighlightText = styled('div')`
	padding-left: 0.25rme;
	display: inline;
`;
