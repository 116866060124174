import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { DominionVotingSystems } from '../../Employment/Dominion Voting Systems';

export const useCase: UseCaseType = {
	title: 'Ballot Saving File System',
	// image: 'https://assets.dear-ux.com/faizhirani/images/Chowbotics Architecture.png',
	employment: DominionVotingSystems,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['The CF200 Tabulator was the first voting machine designed by Dominion Voting Systems (DVS). In an election, voters:', ['Filled out a ballot and inserted it into the front of the Tabulator', 'The ballot was pulled through the scanner and held', 'The ballot was processed by the Tabulator', 'The Tabulator then dropped the ballot into the ballot box'], 'The voter was then instructed by election staff to leave as their vote had been cast.', 'During the ballot processing stage, the Tabulator saved a copy of the ballot to its external flash drive. This stage was documented to take ~3 seconds.', 'In my stress testing, I found that as the external flash drive filled up, the processing stage took longer. The first ballot took ~3 seconds, but by the time several thousand ballots were cast, the delay had increased to ~20 seconds. By ten thousand ballots, it had grown to ~90 seconds.', 'On election day there would be long lines at polling stations. A two minute wait for ballots to be processed would not be accepted by irate voters.', 'At the time, Dominion Voting Systems was a startup with 15 employees, and only 3 software engineers. The other 2 software engineers were busy qualifying ballots, without which, there would be no election. I was a 23 year old Electrical Engineering fresh grad who had just been hired as a Software Engineer.', 'I realized that this was going to fall to me. Either I solved it, or  we would pay the consequences in the press on election day.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['In my research, I learned that the File Allocation Table associated with the FAT32 operating system was the culprit. Before taking drastic measures I reran my stress tests with a variety of other file systems (NTFS, exFAT, UFS, ext, ext2, etc). Some performed better than FAT32, but ultimately, they all suffered from the same issue.', 'I discussed with the other two software engineers the idea of inventing a file system tailored to our specific needs. The Director of Software looked alarmed and bewildered but admitted he couldn’t see another solution.', 'I invented a file system without a File Allocation Table using the .NET framework. It used pointers to locate the first available memory block and size markers to designate the beginning of each saved image. It functioned somewhat like a linked list — reaching an image required traversing all previous images.', 'Additionally, I developed a utility program with a GUI that could be used to correctly format a flash drive, and to extract all saved images from the flash drive.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['When I had a working prototype DVS collectively breathed a sigh of relief.', 'The initial time to process a ballot fell from ~3 seconds, to ~1 second. And this processing time was consistent no matter how many images were saved.', 'The 2005 Montreal Election and the March 2006 Federal Election in British Columbia went off without a hitch. The CF200 Tabulator received very little press — in elections, no press is good press.', 'Many years after my departure from DVS, I was told a story of a Director who dismissed warnings about misusing my flash drive formatting utility, and rendered his laptop beyond repair.', 'The method of saving ballots was revamped in 2014, and at that time, my file system was taken out of circulation. It had an 8 year run.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['I identified the bottleneck early through comprehensive stress testing, and by understanding the underlying causes, I developed a custom file system tailored to solve this specific need. By eliminating reliance on traditional file allocation methods, I prevented delays that would have caused chaos on election day.'],
		},
	],
};
