import * as React from 'react';
import { Company, Location, Spacer, Title, TitleLineWrapper } from '../ProfessionalExperienceSectionStyles';
import { language } from '../ProfessionalExperienceSectionLanguage';
import { ProfessionalExperienceEntryType } from '../../../Types/ProfessionalExperienceLineType';

export type Props = {
	entry: ProfessionalExperienceEntryType;
};

export const TitleLine = (props: Props) => {
	const render = () => {
		let returnValue = (
			<TitleLineWrapper>
				<Title>{props.entry.title}</Title>
				<Spacer />
				<Company>{props.entry.company}</Company>
				<Spacer />
				<Location>{props.entry.location}</Location>
			</TitleLineWrapper>
		);
		if (props.entry.noLeftColumn)
			returnValue = (
				<TitleLineWrapper>
					<Title>{props.entry.title}</Title>
					<Spacer />
					<Company>{props.entry.company}</Company>
				</TitleLineWrapper>
			);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
