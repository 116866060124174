import * as React from 'react';
import { HighlightsWrapper } from './HighlightsStyles';
import { language } from './HighlightsLanguage';
import { PaneLayout, Props as PaneProps } from '../../../../Components/Pane/PaneLayout';
import { generateHighlights } from './helpers/generateHighlights';
import { portfolioHighlights } from '../../../../Schema/CVs/default/PortfolioHighlights';

export type Props = {};

export const HighlightsLayout = (props: Props) => {
	const render = () => {
		const highlightsJSX = generateHighlights(portfolioHighlights);
		const paneProps: PaneProps = {
			title: language.title,
			body: highlightsJSX,
		};
		const returnValue = (
			<HighlightsWrapper>
				<PaneLayout {...paneProps} />
			</HighlightsWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
