export type SchemaType = {
	title: string;
	lines: Array<{
		icon: any;
		text: string | Array<string>;
		href?: string;
	}>;
};

export const schema: SchemaType = {
	title: 'Contact Info',
	lines: [
		{
			icon: ['fas', 'envelope'],
			text: 'faizhirani@gmail.com',
		},
		{
			icon: ['fas', 'phone'],
			text: '1 (647) 998-5102',
		},
		{
			icon: ['fas', 'location-dot'],
			text: ['Toronto, Canada'],
		},
		{
			icon: ['fab', 'linkedin'],
			text: 'LinkedIn',
			href: 'https://www.linkedin.com/in/fahirani/',
		},
		{
			icon: ['fas', 'briefcase'],
			text: 'Portfolio',
			href: 'https://www.faizhirani.com/portfolio',
		},
	],
};
