import { styled } from '@mui/material/styles';

export const AffiliationsWrapper = styled('div')`
	padding-bottom: 2rem;
	margin-bottom: 2rem;
`;

export const ContentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
`;
