import * as React from 'react';
import { MeritPanelWrapper } from './MeritPanelStyles';
import { BackgroundTitleLayout, Props as BackgroundTitleProps } from './Layouts/BackgroundTitleLayout';
import { MeritsContent, Props as MeritContentProps } from './Layouts/MeritsContent';
import { SkillType } from '../../Types/SkillType';
import { TechnologyType } from '../../Types/TechnologyType';
import { CvFilterType } from '../../Types/cvFilterType';

export type Props = {
	title: string | null;
	backgroundColor: string;
	textColor: string;
	lines?: Array<SkillType | TechnologyType>;
	jsx?: React.ReactNode[] | React.ReactNode;
	keywords: CvFilterType;
};

export const MeritPanelLayout = (props: Props) => {
	const render = () => {
		const backgroundTitleProps: BackgroundTitleProps = {
			text: props.title ? props.title : '',
			backgroundColor: props.backgroundColor,
			textColor: props.textColor,
		};
		const meritsContentProps: MeritContentProps = {
			lines: props.lines ? props.lines : [],
			keywords: props.keywords,
		};
		const content = props.jsx ? props.jsx : <MeritsContent {...meritsContentProps} />;
		const title = props.title ? <BackgroundTitleLayout {...backgroundTitleProps} /> : null;
		const returnValue = (
			<MeritPanelWrapper>
				{title}
				{content}
			</MeritPanelWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
