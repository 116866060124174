import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { Bamba } from '../../Employment/Bamba';

export const useCase: UseCaseType = {
	title: 'Daily Bank Branch Checklists',
	image: 'https://assets.dear-ux.com/faizhirani/images/CoCs.jpg',
	employment: Bamba,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["As Bamba was in pursuit of a contract with Diamond Trust Bank of Kenya (DTB), I had a consultation with DTB's Director of Operations. She described her 5-hour daily task of reviewing Microsoft Word Documents emailed from each of 50 Branch Managers answering a series of yes/no questions about the Branch's business the day before.", 'Additionally, she described to me the overhead associated with chasing down delinquent Branch Managers who were late in their submissions.', 'This struck me as alarmingly inefficient, and I immediately envisioned a better solution. I identified this as a great opportunity to impress a prospective client and land a contract.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['Over the next couple of days, I developed a web application to digitize the checklist, enabling Branch Managers to easily submit responses online.', 'I also designed an intuitive table view, where each cell represented a Branch-Question pair, using color-coding to visually communicate answers, and hovering to communicate explanatory notes.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ["The demo to the Bank's executive team was met with  both shock and delight. They instantly recognized that a 5-hour daily task that bogged down a senior resource would be reduced to just 5 minutes. Bamba was awarded the contract on the spot.", 'Through iterative requirement gathering and collaboration, we also eliminated the overhead of chasing down late Branch Managers by implementing periodic email reminders.', 'Since its introduction, DTB has incorporated this software module into its Branch Onboarding process. Today, more than 100 branches across Kenya and Tanzania rely on this system.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['This success stemmed from my deep interest in the day-to-day operations and my focus on leveraging software to enhance productivity.', 'By understanding the details of her day-to-day, and the downstream actions informed by the Branch Checklist, I inferred the structure of the mental model she was populating with the data. I then structured a prototype to match that model. Lastly, through collaboration, the prototype was fine-tuned to achieve the correct fit.'],
		},
	],
};
