import * as React from 'react';
import { EducationContentWrapper, EducationWrapper } from './EducationStyles';
import { language } from './EducationLanguage';
import { SectionTitleLayout, Props as SectionTitleProps } from '../SectionTitle/SectionTitleLayout';
import { EducationEntryType } from '../../../../Types/EducationType';
import { generateEducationItem, Props as GenerateProps } from './helpers/generateEducationItem';

export type Props = {
	title: string;
	backgroundColor: string;
	textColor: string;
	lines: Array<EducationEntryType>;
};

export const EducationLayout = (props: Props) => {
	const sectionTitleProps: SectionTitleProps = {
		text: props.title,
	};
	const generateProps: GenerateProps = {
		lines: props.lines,
	};
	const render = () => {
		const returnValue = (
			<EducationWrapper>
				<SectionTitleLayout {...sectionTitleProps} />
				<EducationContentWrapper>{generateEducationItem(generateProps)}</EducationContentWrapper>
			</EducationWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
