import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	skillsAcquired: ['AWS CDK'],
	noLeftColumn: true,
	showMeOn: ['all'],
	length: '',
	timespan: '',
	title: 'Model Microservice Stack',
	technologies: [],
	tools: ['AWS CDK', 'CodeCommit', 'CodePipeline', 'CodeBuild', 'S3', 'Route 53', 'DynamoDB', 'Lambda', 'SNS', 'SQS', 'IAM', 'CloudFront', 'CloudWatch'],
	languages: ['Javascript', 'TypeScript'],
	company: '2021',
	description: '',
	location: '',
	results: '',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Built a reusable microservice stack, and complete CI/CD pipeline using AWS CDK, automating resource creation and deploys for production and staging environments, ensuring consistency across deployments and improving developer experience thereby increasing agility',
		},
		{
			showMeOn: ['all'],
			entry: 'This stack was adopted as the backbone for KidiUp’s agile infrastructure, and has been the foundation of future projects',
		},
	],
};
