import * as React from 'react';
import { SkillType } from '../../../Types/SkillType';
import { TechnologyType } from '../../../Types/TechnologyType';
import { CvFilterType } from '../../../Types/cvFilterType';
import { MeritLineText } from '../MeritPanelStyles';

export type Props = {
	lines: Array<SkillType | TechnologyType>;
	keywords: CvFilterType;
};

export const generateMeritsLineItems = (props: Props) => {
	const returnValueArray: Array<string> = [];
	const orderedLines = props.lines.sort(compare);
	orderedLines.forEach((line: SkillType | TechnologyType) => {
		let show = line.showOn === undefined || line.showOn.indexOf('all') !== -1;
		if (line.showOn && !show) {
			props.keywords.forEach((keyword: string) => {
				if (line.showOn && line.showOn.indexOf(keyword) !== -1) show = true;
			});
		}
		if (show) returnValueArray.push(line.entry);
	});
	// const returnValue = returnValueArray.map(line => <MeritLineText>{line}</MeritLineText>);
	const returnValue = returnValueArray.join(', ');
	return returnValue;
};

const compare = (a: SkillType | TechnologyType, b: SkillType | TechnologyType) => {
	if (a.entry < b.entry) return -1;
	else if (b.entry < a.entry) return 1;
	else return 0;
};
