import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: 'Notification Streams',
	image: 'https://assets.dear-ux.com/faizhirani/images/Notification Streams.png',
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ["Our CEO questioned one of our Customer Success Representative as to why our Robot (Sally) at The University of Berkley hadn't made any sales recently. Upon investigation, it was discovered that, due to a mechanical malfunction, Sally had been in a disabled state for three days.", 'It was bizarre to me that the discovery of the malfunction occurred after three days, and had the CEO not inquired, would have occurred even later.', 'I identified this as an opportunity to make a massive impact to the company, and took the initiative to design a solution.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['I enabled our Sally robots to emit notifications and defined a series of notification streams with varying levels of severity to which employees could subscribe.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['Our Operations Department, specifically, our Customer Success Representatives, were overjoyed by this feature. Each of their support capacity was increased from ~3  to ~50 Sallys. ', 'The result was a complete overhaul to the way that Operations and Logistics was undertaken.', 'Additionally, the notifications sparked a brainstorming session by our Marketing, Culinary, and Mechanical Engineering  Departments. At their request, many other visualizations that sourced the notifications emitted through the user journey were developed, including a visualization of the funnel through which sales were captured.', 'This was the first Meal Sale Funnel introduced at Chowbotics, and was used Marketing and Culinary Departments to identify obstacles towards which resources could be focused.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ["Chowbotics' network infrastructure, which I designed for agility, was distributed, event-driven, and highly decoupled. This made it easy to broadcast health and exception data from the robots, interpret and transform the data, and push emails and sms notifications to subscribers. Because of the decoupled nature, this could be done easily without disturbing any existing functionality."],
		},
	],
};
