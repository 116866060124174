import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { Chowbotics } from '../../Employment/Chowbotics';

export const useCase: UseCaseType = {
	title: 'Bowl Autocomplete',
	image: 'https://assets.dear-ux.com/faizhirani/images/Bowl Autocomplete.png',
	highlights: ['UX'],
	employment: Chowbotics,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['In analyzing user journey data, I noticed that users spent more time selecting ingredients near the end of the required eight ingredients to make a bowl than when choosing the first few.', 'This observation led me to hypothesize that, contrary to focus group responses and conventional wisdom, users might prefer choosing a few key ingredients they really liked and having the rest of the bowl built around those selections.'],
		},
		{
			sectionTitle: 'Solution Implemented',
			paras: ['I transformed raw historical order data stored in a NoSQL table and imported in to an OLAP table designed to index ingredient correlation. I then built an API and front end widget that suggested ingredients based on synergies with already chosen ingredients.', 'Essentially, it was an crowd-sourced autocomplete feature for bowl ingredients.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['The feature saw immediate adoption across our user base once integrated into the ordering process. It successfully addressed the friction in the ordering process.', 'Quantitatively, we observed that the time spent selecting later ingredients dropped below the time spent on the initial selections. After choosing their preferred ingredients, users felt comfortable streamlining the process by adopting the suggestions.'],
		},
		{
			sectionTitle: 'Why it worked',
			paras: ['Identifying the friction and inferring it could be attributed to increased cognitive load logically led to exploring whether an autocomplete feature would help.', "The quick deployment of a hypothesis-testing prototype was facilitated by Chowbotics' network infrastructure, which I designed with agility in mind. Its distributed, event-driven, and highly decoupled architecture made it simple to create a service that subscribed to notifications already being broadcasted by the robots.", 'Once the feature gained traction with internal testers (ourselves, during lunch breaks), our excited Product and Marketing teams quickly collaborated to refine the front-end assets, ensuring they aligned with our branding and the overall look and feel of our App.'],
		},
	],
};
