export type SchemaType = {
	title: string;
	org: string;
	timespan: string;
};

export const schema: SchemaType[] = [
	{
		title: 'Political Fundraiser for Chrystia Freeland’s Federal Campaign (Canada’s Deputy Prime Minister)',
		org: '',
		timespan: '2019,',
	},
	{
		title: 'Member and Mentor',
		org: 'Women in Leadership (WIL)',
		timespan: '2019 - 2022',
	},
	{
		title: 'Co-Chair',
		org: 'Emerging Health Leaders Forum (ELF)',
		timespan: '2021',
	},
	{
		title: 'Strategic Adviser (Pro Bono)',
		org: 'Dense Breasts Canada (DBC)',
		timespan: '2021',
	},
	{
		title: 'Mentor and Member',
		org: 'How to Change the World (HTCW)',
		timespan: '2023 - Present',
	},
];
