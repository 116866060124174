import { CVHighlightType } from '../../../Types/CVHighlightsType';
import { HighlightItem, Props as HighlightProps } from '../Layouts/HighlightItem';
import React from 'react';

export const generateHighlights = (highlights: Array<CVHighlightType>) => {
	const returnValue = highlights.map((highlight: CVHighlightType, index: number) => {
		const key = 'highlightItem_' + index;
		const outgoingProps: HighlightProps = {
			highlight,
			index,
		};
		const innerReturnValue = <HighlightItem key={key} {...outgoingProps} />;
		return innerReturnValue;
	});
	return returnValue;
};
