import * as React from 'react';
import { AffiliationWrapper, Org, Title, Timespan } from './AffiliationStyles';
import { SchemaType as AffiliationSchema } from '../../../../../Schema/MariamCVs/default/Affiliations';

export type Props = {
	affiliationSchema: AffiliationSchema;
};

export const AffiliationLayout = (props: Props) => {
	const orgJSX = props.affiliationSchema.org === '' ? null : <Org>{props.affiliationSchema.org},</Org>;
	const render = () => {
		const returnValue = (
			<AffiliationWrapper>
				<Title>{props.affiliationSchema.title},</Title>
				{orgJSX}
				<Timespan>{props.affiliationSchema.timespan}</Timespan>
			</AffiliationWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
