import { UseCaseType } from '../../../../Types/Portfolio/UseCaseType';
import { DominionVotingSystems } from '../../Employment/Dominion Voting Systems';

export const useCase: UseCaseType = {
	title: 'Dynamic Configuration Files',
	image: 'https://assets.dear-ux.com/faizhirani/images/DCF Files.png',
	employment: DominionVotingSystems,
	body: [
		{
			sectionTitle: 'Trigger',
			paras: ['In 2006, Dominion Voting Systems was a small startup and I was hired as the ~15th employee. At the time, the CF200 Polling Machine was DVS’ flagship product, and it needed to function across a wide array of environments and ballots.', 'The Director of Software Engineering decided to assign this task to the new hire—a fresh graduate from Electrical Engineering at the University of Toronto.'],
		},
		{
			sectionTitle: 'Solution',
			paras: ['I implemented the DCF files according to the given specifications and tested every configuration to ensure the CF200 Polling Machine behaved as expected.'],
		},
		{
			sectionTitle: 'Impact',
			paras: ['While the DCF files had the expected impact on the company and elections, the effect on me was profound.', 'Beyond the sense of accomplishment, I was filled with a sense of wonder. With the <1kb DCF files I had designed and implemented, we were able to deploy the CF200 Polling Machines:', ['With or without receipt printers', 'In solo configuration', 'In master/slave configuration', 'As part of a network', 'With or without a back-end reporting suite', 'With or without audio voting enabled', 'With customized ballot configurations'], 'Without the need to change any code, we were able to do this all on the fly by updating a set of easily consumable configuration files. Although the term Developer Experience (DX) hadn’t been coined yet, this experience cemented my belief that well-organized, modular code creates significant long-term benefits.'],
		},
	],
};
