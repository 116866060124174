import * as React from 'react';
import { SkillsAcquiredTitle, SkillsAcquiredWrapper } from '../ProfessionalExperienceSectionStyles';
import { language } from '../ProfessionalExperienceSectionLanguage';

export type Props = {
	skillsAcquired: Array<string>;
};

export const SkillsAcquired = (props: Props) => {
	const skills = genrateSkillsAcquired(props.skillsAcquired.sort());
	const render = () => {
		const returnValue =
			props.skillsAcquired.length === 0 ? null : (
				<SkillsAcquiredWrapper>
					<SkillsAcquiredTitle>{language.skillsAcquiredTitle}:</SkillsAcquiredTitle>
					{skills}
				</SkillsAcquiredWrapper>
			);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

const genrateSkillsAcquired = (skills: Array<string>) => {
	const returnValue = skills.join(', ');
	return returnValue;
};
