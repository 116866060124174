import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	showMeOn: ['all'],
	length: '~6 Months',
	timespan: 'May 2022 - Dec 2022',
	title: 'Founder & Chief Technology Officer',
	technologies: ['ReactJS', 'React Native', 'NodeJS', 'NoSQL (DynamoDB)', 'SQL (OLAP)', 'OpenAI', 'Cypress'],
	languages: ['JavaScript', 'Typescript', 'Bash Scripting'],
	tools: ['AWS', 'Google Cloud'],
	company: 'KidiUp',
	description: "A platform to find, book, pay and keep track of children's activities all in one place",
	location: 'Toronto, Ontario',
	results: 'Aug 2022 - Demonstrated feasibility in Aurora, Ontario market',
	details: [
		{
			showMeOn: ['all'],
			entry: 'Developed core web app reducing time to enroll children in activities by 95%, central to the company’s value proposition.',
		},
		{
			showMeOn: ['all'],
			entry: 'Implemented CD/CI pipeline, eliminating human error and ensuring consistent infrastructure and app deployment',
		},
		{
			entry: 'Eliminated 1,800+ hours of quarterly manual work and cut fundraising target by 30% by developing a web crawler with LLM integration to automate activity center data extraction, avoiding the need for an army of contractors',
			showMeOn: ['all'],
		},
		{
			showMeOn: ['all'],
			entry: 'Led focus groups and built flexible product roadmap, adaptable to evolving user needs and insights from market feedback',
		},
		{
			showMeOn: ['all'],
			entry: 'Applied extensive past experience to build an optimized real-time KPI tracking system, enabling data-driven decisions',
		},
		{
			showMeOn: ['mentoring'],
			entry: 'Interviewed, hired, and mentored talent, ensuring code quality and team growth',
		},
		{
			showMeOn: ['marketing'],
			entry: 'Created marketing collateral and managed social media, producing engaging content, including a sketch for a fundraising application',
		},
		{
			showMeOn: ['none'],
			entry: 'Directed, produced, and co-starred in amusing 3-minute sketch for a fundraising application',
		},
	],
};
