import * as React from 'react';
import { Company, DateWrapper, Location, Spacer, Title, TitleLine, UnorderedList, EntryContent, EntryColumnHeadingWrapper, DateColumnWrapper, DetailsWrapper, EntryWrapperEven, EntryWrapperOdd, EmploymentDetails, TimespanWrapper } from './ExperienceEntryStyles';
import { ProfessionalExperienceEntriesType, ProfessionalExperienceEntryType, ProfessionalExperienceLineItemType } from '../../../../../Types/ProfessionalExperienceLineType';
import { useMobile } from '../../../../../utils/useMobile';

export type Props = {
	entry: ProfessionalExperienceEntryType;
	subtype: string;
	odd: boolean;
};

export const ProfessionalExperienceEntry = (props: Props) => {
	const isMobile = useMobile();
	const render = () => {
		const emptyJSX = null;
		const EntryWrapper = props.odd ? EntryWrapperEven : EntryWrapperOdd;

		const timespan = props.entry.timespan.split(';').map((entry) => <TimespanWrapper>{entry}</TimespanWrapper>);
		const desktopJSX = (
			<EntryWrapper>
				<EntryColumnHeadingWrapper>
					<TitleLine>
						<Company>{props.entry.company}</Company>
						<Spacer />
						<Location>{props.entry.location}</Location>
						<DateColumnWrapper>
							<DateWrapper>{timespan}</DateWrapper>
						</DateColumnWrapper>
					</TitleLine>
					<Title>{props.entry.title}</Title>
					<DetailsWrapper>
						<UnorderedList>{generateDetails(props.entry.details, props.subtype)}</UnorderedList>
					</DetailsWrapper>
				</EntryColumnHeadingWrapper>
			</EntryWrapper>
		);

		const mobileJSX = (
			<EntryWrapper>
				<EntryColumnHeadingWrapper>
					<TitleLine>
						<Company>{props.entry.company}</Company>
						<Title>{props.entry.title}</Title>
						<Location>{props.entry.location}</Location>
						<DateColumnWrapper>
							<DateWrapper>{timespan}</DateWrapper>
						</DateColumnWrapper>
					</TitleLine>
					<DetailsWrapper>
						<UnorderedList>{generateDetails(props.entry.details, props.subtype)}</UnorderedList>
					</DetailsWrapper>
				</EntryColumnHeadingWrapper>
			</EntryWrapper>
		);
		const entryJSX = isMobile ? mobileJSX : desktopJSX;
		const returnValue = shouldIShow(props.entry.showMeOn, props.subtype) ? entryJSX : emptyJSX;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};

export const shouldIShow = (showMeOn: Array<string>, subtype: string) => {
	let returnValue = false;
	if (showMeOn.indexOf('all') !== -1) returnValue = true;
	if (showMeOn.indexOf(subtype) !== -1) returnValue = true;
	return returnValue;
};

export const generateDetails = (details: Array<ProfessionalExperienceLineItemType>, subtype: string) => {
	const returnValue = details.map((lineItem) => {
		const shouldIShowBoolean = shouldIShow(lineItem.showMeOn, subtype);
		let conditionalReturnValue: any = null;
		if (shouldIShowBoolean) {
			const entryJSX = <EntryContent>{lineItem.entry}</EntryContent>;
			conditionalReturnValue = entryJSX;
			if (lineItem.children) {
				const childrenJSX = <UnorderedList>{generateDetails(lineItem.children, subtype)}</UnorderedList>;
				conditionalReturnValue = [entryJSX, childrenJSX];
			}
		}
		const innerReturnValue = shouldIShowBoolean ? conditionalReturnValue : null;
		return innerReturnValue;
	});
	return returnValue;
};
