import React from 'react';
import { RouteType } from '../types';
import { MariamCVContainer } from '../../../Pages/MariamCV/MariamCVContainer';
import { MariamCVLayout } from '../../../Pages/MariamCV/MariamCVLayout';

export const routes: Array<RouteType> = [
	{
		path: '/mariamcv/',
		element: <MariamCVContainer Layout={MariamCVLayout} />,
	},
];
