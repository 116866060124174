import { ProfessionalExperienceEntryType } from '../../../../Types/ProfessionalExperienceLineType';

export const schema: ProfessionalExperienceEntryType = {
	skillsAcquired: ['Blender'],
	noLeftColumn: true,
	showMeOn: ['all'],
	length: '',
	timespan: '',
	title: 'Asset Creation',
	technologies: ['Blender'],
	tools: [],
	languages: ['Python'],
	company: '2014 - Present',
	description: '',
	location: '',
	results: '',
	details: [
		{
			showMeOn: ['all'],
			entry: "Took initiative to learn Blender, using it to create branded icons, resulting in a more cohesive branding experience for Bamba's visitors",
		},
		{
			showMeOn: ['all'],
			entry: 'Created animation presented during TechStars to illustrate Bamba’s viral referral algorithm, which contributed to raising of a seed round',
		},
		{
			showMeOn: ['all'],
			entry: 'Applied Blender skills across multiple professional and personal engagements, and sensitivity gained to the intricacies of graphic design has improved my ability to collaborate effectively with design teams',
		},
	],
};
