import { ToolsType } from '../../../Types/ToolsType';
import { TechnologyType } from '../../../Types/TechnologyType';

export type SchemaType = {
	title: string;
	lines: Array<TechnologyType>;
};

export const schema: SchemaType = {
	title: 'Tools & Tech',
	lines: [
		// Architecture & Cloud
		{
			entry: 'AWS',
			showOn: ['all'],
			omitOn: ['aws'],
		},
		{
			entry: 'GCP',
			showOn: ['all'],
			omitOn: ['gcp'],
		},
		{
			entry: 'MS Azure',
			showOn: ['all'],
			omitOn: ['azure'],
		},

		{
			entry: 'RESTful APIs',
			showOn: ['all'],
		},
		{
			entry: 'Google Cloud Functions',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS Lambda',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Functions',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Pub/Sub',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS SNS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Service Bus',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Tasks',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS SQS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Queue Storage',
			showOn: ['azure'],
		},
		{
			entry: 'Google Compute Engine',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS EC2',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Virtual Machines',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud CDN',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS CloudFront',
			showOn: ['aws'],
		},
		{
			entry: 'Azure CDN',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Firestore',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS DynamoDB',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Cosmos DB',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud SQL',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS RDS',
			showOn: ['aws'],
		},
		{
			entry: 'Azure SQL Database',
			showOn: ['azure'],
		},
		{
			entry: 'Google BigQuery',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS Redshift',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Synapse Analytics',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Storage',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS S3',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Blob Storage',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Build',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS CodePipeline',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Pipelines',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Build',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS CodeBuild',
			showOn: ['aws'],
		},
		{
			entry: 'Azure DevOps Build',
			showOn: ['azure'],
		},
		{
			entry: 'Google Cloud Source Repositories',
			showOn: ['gcp'],
		},
		{
			entry: 'AWS Code Commit',
			showOn: ['aws'],
		},
		{
			entry: 'Azure Repos',
			showOn: ['azure'],
		},
		{
			entry: 'OpenAI',
			showOn: ['all'],
		},

		// Databases
		{
			entry: 'NoSQL Databases',
			showOn: ['databases', 'nosql'],
		},
		{
			entry: 'SQL Databases',
			showOn: ['databases', 'sql'],
		},

		// Programming Languages & Frameworks
		{
			entry: 'NodeJS',
			showOn: ['javascript', 'backend', 'fullstack'],
		},
		{
			entry: 'Express',
			showOn: ['javascript', 'backend', 'fullstack'],
		},
		{
			entry: 'Django',
			showOn: ['python'],
		},
		{
			entry: 'Flask',
			showOn: ['python'],
		},
		{
			entry: 'PHP',
			showOn: ['php', 'lamp'],
		},
		{
			entry: 'C',
			showOn: ['c'],
		},
		{
			entry: 'C++',
			showOn: ['c++'],
		},
		{
			entry: 'Java',
			showOn: ['java'],
		},
		{
			entry: 'Bash Scripting',
			showOn: ['bash'],
		},

		// Frontend & Full-Stack
		{
			entry: 'React JS',
			showOn: ['javascript', 'front end', 'fullstack'],
		},
		{
			entry: 'React Native',
			showOn: ['javascript', 'front end', 'fullstack'],
		},
		{
			entry: 'Angular',
			showOn: ['javascript', 'front end', 'fullstack'],
		},
		{
			entry: 'Typescript',
			showOn: ['javascript', 'front end', 'fullstack'],
		},
	],
};
