import * as React from 'react';
import { SectionTitleWrapper } from './SectionTitleStyles';
import { language } from './SectionTitleLanguage';

export type Props = {
	text: string;
	fullWidth?: boolean;
};

export const SectionTitleLayout = (props: Props) => {
	const render = () => {
		const returnValue = <SectionTitleWrapper>{props.text}</SectionTitleWrapper>;
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
