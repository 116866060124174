import { EducationEntryType } from '../../../Types/EducationType';

export type SchemaType = {
	title: string;
	lines: Array<EducationEntryType>;
};

export const schema: SchemaType = {
	title: 'Education',
	lines: [
		{
			degree: 'Government Relations Management Certificate (GRMC)',
			school: 'Seneca Polytechnic',
			location: 'Toronto, Ontario',
			timespan: '2018',
		},
		{
			degree: 'Master of Public Service (MPS), Public Policy & Public Administration',
			school: 'University of Waterloo',
			location: 'Waterloo, Ontario',
			timespan: '2012',
		},
		{
			degree: 'Honours Bachelor of Arts (HBA), Political Science, European History & Middle Eastern Studies',
			school: 'University of Toronto',
			location: 'London, Ontario',
			timespan: '2008',
		},
	],
};
