import styled from 'styled-components';
import { cssVarColors } from '../../Constants';

export const FancyHeaderWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-content: flex-start;
`;

export const Titles = styled('div')`
	line-height: 4em;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Title = styled('div')`
	padding-bottom: 1em;
	border: 3px solid ${cssVarColors.highlight};
	font-size: 3em;
	flex: 3;
	text-align: center;
	@media print {
		font-size: 1.75em;
		padding-top: 0.5em;
		padding-bottom: 0.75em;
		border: 1.5px solid ${cssVarColors.highlight};
	}
`;

export const Subtitle = styled('div')`
	padding-top: 2rem;
	padding-bottom: 1rem;
	font-size: 2em;
	flex: 2;
	text-align: left;
	color: #fff;
	position: relative;
	-webkit-print-color-adjust: exact;
	@media print {
		padding-top: 1rem;
	}
`;
