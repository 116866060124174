import * as React from 'react';
import { BodySection, CvWrapper, ExternalWrapper } from './MariamCVStyles';
import { ProfessionalExperienceSectionLayout, Props as ProfessionalExperienceProps } from '../../Components/ProfessionalExperienceSection/ProfessionalExperienceSectionLayout';
import { EducationPanelLayout, Props as EducationPanelProps } from '../../Components/EducationPanel/EducationPanelLayout';
import { SchemaType as EducationSchemaType } from '../../Schema/CVs/default/Education';
import { cssVarColors } from '../../Constants';
import { useSearchParams } from 'react-router-dom';
import { CvFilterType } from '../../Types/cvFilterType';
import { ProfessionalExperienceEntriesType } from '../../Types/ProfessionalExperienceLineType';

import { SchemaType as SkillsSchemaType } from '../../Schema/CVs/default/Skills';
import { SchemaType as TechnologySchemaType } from '../../Schema/CVs/default/Technologies';
import { SchemaType as ContactSchemaType } from '../../Schema/CVs/default/ContactInfo';
import { SchemaType as ToolsSchemaType } from '../../Schema/CVs/default/Tools';
import { SchemaType as IdeologiesSchemaType } from '../../Schema/CVs/default/Ideologies';
import { SchemaType as CoreCompetenciesSchemaType } from '../../Schema/CVs/default/Core Competencies';
import { SchemaType as ProfileSchemaType } from '../../Schema/MariamCVs/default/AboutMe';
import { SchemaType as AffiliationsType } from '../../Schema/MariamCVs/default/Affiliations';
import { HeadingLayout, Props as HeadingProps } from './Layouts/Heading/HeadingLayout';
import { EducationLayout } from './Layouts/Education/EducationLayout';
import { ProfileLayout, Props as ProfileProps } from './Layouts/Profile/ProfileLayout';
import { ExperienceLayout } from './Layouts/Experience/ExperienceLayout';
import { AffiliationsLayout, Props as AffilationProps } from './Layouts/Affiliations/AffiliationsLayout';

export type Props = {
	educationSchema: EducationSchemaType;
	professionalExperienceSchema: ProfessionalExperienceEntriesType;
	affiliations: AffiliationsType[];
	aboutMeSchemas: {
		skillsSchema: SkillsSchemaType;
		technologySchema: TechnologySchemaType;
		contactSchema: ContactSchemaType;
		toolsSchema: ToolsSchemaType;
		ideologiesSchema: IdeologiesSchemaType;
		coreCompetenciesSchema: CoreCompetenciesSchemaType;
		profileSchema: ProfileSchemaType;
	};
};

export const MariamCVLayout = (props: Props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const render = () => {
		let filter = searchParams.get('filter');

		const educationProps: EducationPanelProps = {
			title: props.educationSchema.title,
			backgroundColor: cssVarColors.onSurface,
			textColor: '#fff',
			lines: props.educationSchema.lines,
		};
		const profileProps: ProfileProps = {
			profileSchema: props.aboutMeSchemas.profileSchema,
		};
		const professionalExperienceProps: ProfessionalExperienceProps = {
			professionExperienceSchema: props.professionalExperienceSchema,
		};

		const headingProps: HeadingProps = {
			contactSchema: props.aboutMeSchemas.contactSchema,
		};

		const affiliationsProps: AffilationProps = {
			affiliations: props.affiliations,
		};

		const returnValue = (
			<ExternalWrapper>
				<CvWrapper>
					<HeadingLayout {...headingProps} />
					<BodySection>
						<ProfileLayout {...profileProps} />
						<ExperienceLayout {...professionalExperienceProps} />
						{/*<ProfessionalExperienceSectionLayout {...professionalExperienceProps}/>*/}
						<EducationLayout {...educationProps} />
						<AffiliationsLayout {...affiliationsProps} />
					</BodySection>
				</CvWrapper>
			</ExternalWrapper>
		);
		return returnValue;
	};
	const outerReturnValue = render();
	return outerReturnValue;
};
